#safearea {
  display: block;
}
:root {
  --block-hover: #ededed;
  --text-p1: #222;
  --text-p3: #777;
  --card: #fff;
}
::-moz-selection {
  background: rgba(33,150,243,0.2);
}
::selection {
  background: rgba(33,150,243,0.2);
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 1rem;
}
h1,
h2,
h3,
h4,
h6 {
  font-weight: normal;
}
a:not([href]) {
  cursor: default;
}
pre {
  tab-size: 4;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  -webkit-tab-size: 4;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: table;
}
.clearfix:after {
  clear: both;
}
.hidden {
  text-indent: -9999px;
  visibility: hidden;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
.inner {
  position: relative;
  width: 80%;
  max-width: 710px;
  margin: 0 auto;
}
.vertical {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: table-cell;
  vertical-align: middle;
}
article,
aside,
details,
figcaption,
figure,
footer,
hgroup,
main,
menu,
nav,
section,
summary {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
article {
  overflow: hidden;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 50%;
  line-height: 1em;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
figure {
  margin: 1em 40px;
}
pre {
  overflow: auto;
}
span.dot,
span.sep {
  font-size: 0.9em;
  margin: 0 0.2rem;
}
span.dot:before {
  content: '·';
}
span.sep:before {
  content: '/';
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
optgroup,
select,
textarea {
  color: inherit /* 1 */;
  font: inherit /* 2 */;
  margin: 0 /* 3 */;
}
select {
  text-transform: none;
}
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button /* 2 */;
  cursor: pointer /* 3 */;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box /* 1 */;
  -webkit-box-sizing: border-box /* 1 */;
  -moz-box-sizing: border-box /* 1 */;
  padding: 0 /* 2 */;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield /* 1 */;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0 /* 1 */;
  padding: 0 /* 2 */;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table:not([class]) {
  border-collapse: collapse;
  overflow: auto;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  max-width: 100%;
  vertical-align: text-top;
}
table:not([class]) th {
  background-color: #f1f1f1;
}
table:not([class]) td,
table:not([class]) th {
  padding: 8px 16px;
  border: 2px solid #f1f1f1;
  line-height: 1.5;
  font-size: 90%;
}
table:not([class]) tr {
  word-break: keep-all;
  background-color: #fefefe;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
table:not([class]) tr:hover {
  background-color: #f1f1f1;
}
td,
th {
  padding: 0;
}
article#arc,
article#cat,
article#tag {
  padding-top: 48px;
  padding-bottom: 48px;
}
article#arc h2,
article#cat h2,
article#tag h2 {
  font-weight: 600;
}
article#arc h2:first-child,
article#cat h2:first-child,
article#tag h2:first-child {
  margin-top: 0;
}
article#arc {
  margin-bottom: 32px;
  padding-bottom: 64px;
}
article#arc .timenode:before,
article#arc .timenode:after {
  margin-left: 12px;
}
article#arc .timenode .meta {
  padding: 6px 0;
  line-height: 1.5;
  height: auto;
  max-width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  color: var(--color-list);
}
article#arc .timenode .meta:before {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
article#arc .timenode .meta:after {
  margin-left: 14px;
}
article#arc .timenode .meta:hover {
  color: var(--color-p);
  background: var(--color-site-bg);
}
article#arc .timenode .meta time {
  color: var(--color-meta);
  margin-left: 34px;
  margin-right: 4px;
  flex-shrink: 0;
  width: 60px;
}
article#arc .timenode .meta i {
  line-height: 1.5;
  color: #ff5722;
}
article#arc .timenode .meta i.red {
  color: #fe5f58;
}
article#arc .timenode .meta i.green {
  color: #3dc550;
}
article#arc .timenode .meta i.yellow {
  color: #ffbd2b;
}
article#arc .timenode .meta i.blue {
  color: #1bcdfc;
}
article#arc .timenode .meta i.theme {
  color: #3dd9b6;
}
article#arc .timenode .meta i.accent {
  color: #ff5722;
}
article#arc .timenode .meta i.orange {
  color: #ff5722;
}
article#cat .all-cats a {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  padding: 8px 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  color: var(--color-list);
  font-size: 0.9375rem;
  font-weight: 500;
}
article#cat .all-cats a:hover {
  color: var(--color-list-hl);
  background: var(--color-site-bg);
}
article#cat .all-cats a.child {
  padding-left: 48px;
}
article#tag .all-tags {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  align-items: baseline;
  text-align: center;
}
article#tag .all-tags ul {
  margin: 0 -8px;
  padding: 0;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
article#tag .all-tags ul li {
  list-style: none;
  margin: 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  position: relative;
  font-size: 0.9375rem;
}
article#tag .all-tags ul li a {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  color: var(--color-list);
  padding: 4px 52px 4px 16px;
  background: var(--color-block);
  font-weight: 500;
}
article#tag .all-tags ul li a:hover {
  background: #ff5722;
  color: #fff;
}
article#tag .all-tags ul li span {
  color: var(--color-meta);
  background: var(--color-card);
  padding: 2px 8px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  pointer-events: none;
  position: absolute;
  right: 2px;
  top: 2px;
  height: calc(100% - 4px);
}
article#tag .all-tags ul li span:before {
  content: 'x';
}
.article {
  color: var(--color-p);
  word-wrap: break-word;
}
.article a {
  word-break: break-word;
}
.article h1.title,
.article h2.title {
  left: 0;
}
.article h1.title:before,
.article h2.title:before {
  content: none;
}
.article h1,
.article h2 {
  padding-bottom: 0.2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(68,68,68,0.1);
}
.article h1 {
  text-align: left;
  color: var(--color-h1);
  margin-top: 48px;
}
.article h2 {
  text-align: left;
  color: var(--color-h2);
  margin-top: 48px;
}
.article h3 {
  text-align: left;
  color: var(--color-h3);
  margin-top: 24px;
}
.article h4 {
  text-align: left;
  color: var(--color-h4);
  margin-top: 16px;
}
.article h5 {
  font-weight: bold;
  color: var(--color-h5);
  margin-top: 1em;
}
.article h6 {
  color: var(--color-h6);
  margin-top: 1em;
}
.article center,
.article center p {
  text-align: center;
}
.article .aplayer {
  margin: 1em 0;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  width: 400px;
  max-width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #666;
}
.article p.small-img img,
.article div.small-img img {
  width: auto;
  max-width: 100%;
  margin: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.article s {
  color: #8e8e8e;
  -webkit-text-decoration-color: #8e8e8e;
          text-decoration-color: #8e8e8e;
}
.article p {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: justify;
  max-width: 100%;
  line-height: inherit;
}
.article .subtitle h6 {
  color: rgba(68,68,68,0.9);
}
.article figure figcaption span {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  margin-right: 5px;
}
.article blockquote {
  background: var(--color-block);
  border-left: 4px solid #3dd9b6;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.article blockquote {
  position: relative;
  width: 100%;
  padding: 16px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.article blockquote,
.article blockquote p,
.article blockquote ul,
.article blockquote ol {
  text-align: left;
  word-wrap: normal;
  font-size: 0.9375rem;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.article blockquote footer {
  padding: 0;
  text-align: justify;
  color: inherit;
  font-style: italic;
  margin: 1em 0;
}
.article blockquote footer cite {
  color: var(--color-meta);
  margin-left: 1em;
}
.article blockquote footer cite::before {
  content: '----';
  padding: 0 0.3em;
}
.article blockquote.pullquote.right {
  border-left: none;
  border-right: 4px solid #3dd9b6;
}
.article blockquote.pullquote.right p {
  text-align: right;
}
.article pre {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-top: 1em;
  margin-bottom: 1em;
  overflow: auto;
  background: var(--color-codeblock);
  font-size: 0.8125rem;
  font-family: Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
  border: 1px solid #ffebcb;
  padding: 16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.article pre >code:not([class]) {
  background: transparent;
}
.article div>pre {
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.article div>pre>code:not([class]) {
  padding: 0;
  margin: 0;
  background: transparent;
  color: rgba(68,68,68,0.9);
}
.article code {
  font-family: Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
}
.article code:not([class]) {
  word-break: break-all;
  color: var(--color-inlinecode);
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
@media screen and (max-width: 500px) {
  .article ul,
  .article ol {
    font-size: 0.875rem;
  }
  .article figure {
    font-size: 13px;
    line-height: 1.5;
  }
}
.article .widget {
  background: transparent;
  margin: 1em 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  cursor: auto;
  background: var(--color-block);
  padding: 8px 0;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  width: 100%;
}
.article .widget:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.article .widget:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.article .widget header {
  padding: 4px 0.6em;
  padding-bottom: 0;
}
.article .widget header,
.article .widget header a {
  color: rgba(68,68,68,0.85);
}
.article .widget.copyright,
.article .widget.qrcode {
  background: none;
  padding: 0;
}
.article .widget.copyright header,
.article .widget.qrcode header {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
.article .widget.copyright .content,
.article .widget.qrcode .content {
  padding: 0;
}
.article .widget.list .content,
.article .widget.related_posts .content {
  padding: 0 0.6em !important;
}
.article .widget.list .content a,
.article .widget.related_posts .content a {
  color: #2092ec;
}
.article .widget.list .content a:hover,
.article .widget.related_posts .content a:hover {
  color: #ff5722;
}
.article .widget .content {
  padding: 0 0.6em;
  margin: 0;
}
.article .widget .content ul {
  padding-left: 4px;
  margin-left: 16px;
}
.article .widget .content ul a {
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -khtml-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  border-left: none;
  padding: 0;
  padding-left: 4px;
  color: #2092ec;
  font-weight: normal;
  text-decoration: none;
}
.article .widget .content ul a:hover,
.article .widget .content ul a.active,
.article .widget .content ul a:active {
  border-left: none !important;
  background: none !important;
}
.article .widget .content ul a:hover {
  color: #ff5722;
}
.article .widget .content .list a .name {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  color: #2092ec;
}
.article .widget .content .list a:hover .name {
  color: #ff5722;
}
.article .widget.qrcode > .content {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 4px;
}
.article .widget.qrcode > .content>.fancybox,
.article .widget.qrcode > .content>img {
  margin: 0 8px;
}
.article .widget.qrcode > .content img {
  margin-bottom: 4px;
}
.article .article_footer {
  margin-top: 64px;
}
.article .widget-blur {
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.md .footer {
  margin-top: 64px;
}
.md .footer >div {
  margin-top: 1em;
  margin-bottom: 1em;
}
.md .footer .header {
  line-height: 1.75;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--color-list);
}
.md .footer .header i {
  margin-right: 2px;
}
.md .footer .body ul,
.md .footer .body ol {
  margin-top: 0;
  margin-bottom: 0;
}
.md .footer .references,
.md .footer .related_posts {
  background: var(--color-block);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  padding: 16px;
}
.md .footer .references .body a {
  font-size: 0.9375rem;
  font-weight: 500;
}
.md .footer .related_posts .body {
  margin: 4px;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
.md .footer .related_posts .body .vlts-rps {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
}
.md .footer .related_posts .body .vlts-rps .item {
  flex-shrink: 0;
  width: 240px;
}
.md .footer .related_posts .body .vlts-rps .item+.item {
  margin-left: 16px;
}
.md .footer .related_posts .body .vlts-rps .item:hover img {
  filter: ;
}
.md .footer .related_posts .body .vlts-rps img {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 100%;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  filter: ;
}
.md .footer .related_posts .body .vlts-rps span {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: justify;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.md .footer .related_posts .body .vlts-rps .title {
  font-weight: 600;
  -webkit-line-clamp: 1;
}
.md .footer .related_posts .body .vlts-rps .excerpt {
  font-size: 0.875rem;
  color: var(--color-meta);
  -webkit-line-clamp: 3;
}
.md .footer .copyright blockquote p {
  font-size: 0.875rem;
  margin: 0.25em 0;
}
.md .footer .copyright blockquote p a {
  font-weight: 500;
}
.md .footer .donate {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  margin: 0 auto;
}
.md .footer .donate .imgs {
  display: inline-flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: inline-flex;
  margin: 0 auto;
}
.md .footer .donate .imgs .fancybox {
  margin: 8px;
}
.md .footer .donate .imgs img {
  width: 80px;
}
article .readmore {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin-top: 24px;
  font-size: 0.875rem;
}
.copyright.license {
  background: var(--color-copyright-bkg);
  color: var(--color-meta);
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  font-size: 0.95rem;
  line-height: 1.2;
  margin: 15px -40px;
  overflow: hidden;
  padding: 1.25em 40px;
  position: relative;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.copyright.license:after {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 367.467 367.467' style='enable-background:new 0 0 367.467 367.467;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M183.73,0.018C82.427,0.018,0,82.404,0,183.733c0,101.289,82.427,183.716,183.73,183.716 c101.315,0,183.737-82.427,183.737-183.716C367.467,82.404,285.045,0.018,183.73,0.018z M183.73,326.518 c-78.743,0-142.798-64.052-142.798-142.784c0-78.766,64.055-142.817,142.798-142.817c78.752,0,142.807,64.052,142.807,142.817 C326.536,262.466,262.481,326.518,183.73,326.518z'/%3E%3Cpath d='M244.036,217.014c-11.737,20.141-33.562,32.635-56.956,32.635c-36.329,0-65.921-29.585-65.921-65.915 c0-36.36,29.592-65.955,65.921-65.955c23.395,0,45.219,12.54,56.956,32.641l1.517,2.627h44.28l-2.658-7.129 c-7.705-20.413-21.225-37.769-39.122-50.157c-17.942-12.42-39.017-19.009-60.973-19.009c-58.981,0-106.946,48.006-106.946,106.982 c0,58.98,47.965,106.941,106.946,106.941c21.956,0,43.03-6.567,60.973-19.006c17.897-12.391,31.417-29.741,39.122-50.154 l2.658-7.133h-44.28L244.036,217.014z'/%3E%3C/g%3E%3C/svg%3E");
  content: " ";
  opacity: 0.1;
  -webkit-opacity: 0.1;
  -moz-opacity: 0.1;
  height: 180px;
  right: -10px;
  top: -35px;
  width: 180px;
  position: absolute;
}
.copyright.license a {
  color: var(--color-meta);
}
.copyright.license a:hover {
  color: #ff5722;
}
.copyright.license .license-title,
.copyright.license .license-meta-title {
  margin: 0 0 0.25rem;
}
.copyright.license .license-link,
.copyright.license .license-meta-title {
  font-size: 0.8rem;
}
.copyright.license .license-title {
  font-weight: 700;
}
.copyright.license .license-link {
  margin-bottom: 1rem;
}
.copyright.license .license-meta {
  align-items: center;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
}
.copyright.license .license-meta-item {
  margin: 0 2rem 1em 0;
}
.copyright.license .license-meta-text {
  margin: 0;
}
.copyright.license .license-meta-text a {
  border-bottom: 1px solid var(--color-meta);
}
.copyright.license .license-meta-text a:hover {
  border-bottom-color: #ff5722;
}
.recommended-article {
  overflow: hidden;
}
.recommended-article .recommended-article-header {
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 0;
}
.recommended-article .recommended-article-group {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .recommended-article .recommended-article-group {
    height: 190px;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  .recommended-article .recommended-article-group::-webkit-scrollbar {
    width: 0 !important;
  }
}
.recommended-article .recommended-article-group .recommended-article-item {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(100%/3 - 16px);
  max-height: 200px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .recommended-article .recommended-article-group .recommended-article-item {
    width: calc(100%/2 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .recommended-article .recommended-article-group .recommended-article-item {
    width: calc(100% - 16px);
  }
}
.recommended-article .recommended-article-group .recommended-article-item img {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  width: 100%;
  height: 150px;
}
.recommended-article .recommended-article-group .recommended-article-item span {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: justify;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
footer.footer {
  position: relative;
  padding: 40px 10px 120px 10px;
  width: 100%;
  color: var(--color-site-footer);
  margin: 0px auto;
  overflow: hidden;
  text-align: center;
}
footer.footer,
footer.footer p {
  font-size: 0.8125rem;
}
footer.footer .licenses {
  color: fade(, 50%);
}
footer.footer .social-wrapper {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 4px 8px;
}
footer.footer a {
  color: var(--color-site-footer);
  padding: 0;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
footer.footer a:hover {
  color: #ff5722;
}
footer.footer a:not(.social):hover {
  text-decoration: underline;
}
footer.footer a.social {
  position: relative;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  text-align: center;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  min-width: 36px;
  min-height: 36px;
  margin: 4px;
  opacity: 0.75;
  -webkit-opacity: 0.75;
  -moz-opacity: 0.75;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  font-size: 1rem;
}
footer.footer a.social img {
  margin: 8px;
  height: 24px;
}
footer.footer a.social:hover {
  color: #ff5722;
  background: rgba(255,87,34,0.1);
}
footer.footer .copyright {
  margin-top: 16px;
}
footer.footer .copyright p {
  font-size: 0.78125rem;
}
@media screen and (max-width: 768px) {
  footer.footer {
    justify-content: center;
    -webkit-justify-content: center;
    -khtml-justify-content: center;
    -moz-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }
}
.article.l_friends .friends-group .friend-content {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: -8px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  align-items: flex-start;
  line-height: 1.3;
}
.article.l_friends .friends-group .friend-content .friend-card {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  padding: 8px 0;
  margin: 8px;
  margin-top: calc(2.25 * 16px + 32px);
  color: var(--color-meta);
  background: var(--color-block);
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: calc(100%/4 - 16px);
}
@media screen and (max-width: 1024px) {
  .article.l_friends .friends-group .friend-content .friend-card {
    width: calc(100%/4 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .article.l_friends .friends-group .friend-content .friend-card {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .article.l_friends .friends-group .friend-content .friend-card {
    width: calc(100%/2 - 16px);
  }
}
.article.l_friends .friends-group .friend-content .friend-card:hover .friend-left .avatar {
  transform: scale(1.2) rotate(12deg);
  -webkit-transform: scale(1.2) rotate(12deg);
  -khtml-transform: scale(1.2) rotate(12deg);
  -moz-transform: scale(1.2) rotate(12deg);
  -o-transform: scale(1.2) rotate(12deg);
  -ms-transform: scale(1.2) rotate(12deg);
  box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
.article.l_friends .friends-group .friend-content .friend-card .friend-left {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-self: center;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-left .avatar {
  width: 64px;
  height: 64px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  margin: 16px 8px 4px 8px;
  margin-top: calc(-1.25 * 16px - 32px);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 2px solid #fff;
  background: #fff;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right {
  margin: 4px 8px;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right p {
  text-align: center;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right .friend-tags-wrapper {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  margin-left: -2px;
  word-break: break-all;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right p {
  margin: 0;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right p.friend-name {
  font-size: 0.8125rem;
  padding-top: 4px;
  font-weight: bold;
}
.article.l_friends .friends-group .friend-content .friend-card .friend-right p.tags {
  font-size: 0.78125rem;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  background: none;
  word-wrap: break-word;
  padding-right: 4px;
}
.md img {
  position: relative;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
@media screen and (max-width: 500px) {
  .md img {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
.md div>img,
.md p>img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin: auto;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
@media screen and (max-width: 500px) {
  .md div>img,
  .md p>img {
    border-radius: 2px;
    -webkit-border-radius: 2px;
  }
}
.md span img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  margin: auto;
}
.md .img-wrap {
  margin: 1.5rem auto;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  overflow: hidden;
}
.md .img-wrap .img-bg {
  width: 100%;
}
.md .img-wrap .image-caption {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin: 0.75rem auto;
  font-size: 0.8125rem;
  color: var(--color-meta);
}
.md .img-wrap .image-caption:empty {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
svg.loading {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: absolute;
  color: var(--text-p3);
  width: 100%;
  height: 2rem;
  margin: auto;
  animation: spin infinite 2s;
  -webkit-animation: spin infinite 2s;
  -khtml-animation: spin infinite 2s;
  -moz-animation: spin infinite 2s;
  -o-animation: spin infinite 2s;
  -ms-animation: spin infinite 2s;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -khtml-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -khtml-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}
#safearea {
  margin: 16px 16px 0;
}
#l_body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#l_body div.loading {
  margin: 16px 0;
  width: 100%;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
#l_body div.loading,
#l_body div.loading p {
  text-align: center;
}
#l_body #s-top {
  transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -khtml-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  z-index: 50;
  position: fixed;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  bottom: 32px;
  right: 32px;
  transform: translateY(100px) scale(0);
  -webkit-transform: translateY(100px) scale(0);
  -khtml-transform: translateY(100px) scale(0);
  -moz-transform: translateY(100px) scale(0);
  -o-transform: translateY(100px) scale(0);
  -ms-transform: translateY(100px) scale(0);
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
  -khtml-transform-origin: bottom;
  -moz-transform-origin: bottom;
  -o-transform-origin: bottom;
  -ms-transform-origin: bottom;
  color: var(--color-text);
}
@media screen and (max-width: 768px) {
  #l_body #s-top {
    right: 16px;
  }
}
#l_body #s-top.show {
  transform: translateY(0) scale(1);
  -webkit-transform: translateY(0) scale(1);
  -khtml-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  -o-transform: translateY(0) scale(1);
  -ms-transform: translateY(0) scale(1);
}
#l_body #s-top.show.hl {
  background: #3dd9b6;
  color: #fff;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
}
@media screen and (min-width: 768px) {
  #l_body #s-top:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -khtml-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    border-radius: 25%;
    -webkit-border-radius: 25%;
    background: #3dd9b6;
    color: #fff;
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  }
  #l_body #s-top:hover.hl {
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  }
}
#l_main {
  width: calc(100% - 1 * 240px);
  padding-right: 16px;
  float: left;
}
@media screen and (max-width: 768px) {
  #l_main {
    width: 100%;
  }
}
#l_main.no_sidebar {
  width: 100%;
  padding-right: 0;
  max-width: 840px;
  margin: auto;
}
@media screen and (min-width: 2048px) {
  #l_main.no_sidebar {
    max-width: calc(55vw - 240px);
  }
}
#l_main.no_sidebar ~#l_side {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
#l_main .post-list {
  position: relative;
  margin-bottom: 16px;
  column-gap: 16px;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
}
#l_main .post-list.multiple-columns {
  -moz-columns: 320px;
       columns: 320px;
}
#l_main .post-wrapper {
  column-break-inside: avoid;
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
}
#l_main .widget .content p,
#l_main .widget .content ul,
#l_main .widget .content ol,
#l_main .widget .content table,
#l_main .widget .content .tabs,
#l_main .widget .content details {
  margin-top: 1em;
  margin-bottom: 1em;
}
#l_main .widget .content .post {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}
#l_main .widget.grid .content .grid.fixed a {
  width: calc(100%/8 - 0 * 16px);
}
@media screen and (max-width: 1024px) {
  #l_main .widget.grid .content .grid.fixed a {
    width: calc(100%/7 - 0 * 16px);
  }
}
@media screen and (max-width: 768px) {
  #l_main .widget.grid .content .grid.fixed a {
    width: calc(100%/6 - 0 * 16px);
  }
}
@media screen and (max-width: 500px) {
  #l_main .widget.grid .content .grid.fixed a {
    width: calc(100%/5 - 0 * 16px);
  }
}
@media screen and (max-width: 425px) {
  #l_main .widget.grid .content .grid.fixed a {
    width: calc(100%/4 - 0 * 16px);
  }
}
@media screen and (max-width: 375px) {
  #l_main .widget.grid .content .grid.fixed a {
    width: calc(100%/3 - 0 * 16px);
  }
}
#l_main .post {
  position: relative;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
#l_main .post h1.title {
  font-size: 1.5rem;
  margin: 0;
  border-bottom: none;
  padding-bottom: 4px;
  border-bottom: none;
}
#l_main .post .article-meta {
  color: var(--color-meta);
  margin-bottom: 16px;
  line-height: normal;
}
#l_main .post .article-meta#top {
  margin-top: 16px;
  margin-bottom: 32px;
}
#l_main .post .article-meta#bottom {
  margin-top: 32px;
  margin-bottom: 8px;
}
#l_main .post .article-meta .aplayer,
#l_main .post .article-meta .aplayer-pic,
#l_main .post .article-meta .thumbnail {
  width: 48px;
  height: 48px;
}
#l_main .post .article-meta .aplayer,
#l_main .post .article-meta .thumbnail {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  float: right;
  margin: 2px;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
}
#l_main .post .article-meta .aplayer:hover,
#l_main .post .article-meta .thumbnail:hover {
  border-radius: 25%;
  -webkit-border-radius: 25%;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -khtml-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
@media screen and (max-width: 500px) {
  #l_main .post .article-meta .aplayer:hover,
  #l_main .post .article-meta .thumbnail:hover {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -khtml-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  }
}
#l_main .post .article-meta .thumbnail {
  width: auto;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
#l_main .post .article-meta .thumbnail:hover {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  transform: scale(1.1) rotate(4deg);
  -webkit-transform: scale(1.1) rotate(4deg);
  -khtml-transform: scale(1.1) rotate(4deg);
  -moz-transform: scale(1.1) rotate(4deg);
  -o-transform: scale(1.1) rotate(4deg);
  -ms-transform: scale(1.1) rotate(4deg);
  box-shadow: none;
  -webkit-box-shadow: none;
}
#l_main .post .article-meta .new-meta-box {
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -khtml-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
#l_main .post .article-meta .new-meta-box,
#l_main .post .article-meta .new-meta-box p,
#l_main .post .article-meta .new-meta-box i {
  font-size: 0.8125rem;
}
#l_main .post .article-meta .new-meta-box .new-meta-item {
  color: var(--color-meta);
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-items: baseline;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  margin: 0 16px 0 0;
  padding: 8px 0;
}
#l_main .post .article-meta .new-meta-box .new-meta-item .notlink {
  cursor: default;
}
#l_main .post .article-meta .new-meta-box .new-meta-item .notlink:hover {
  color: var(--color-meta);
}
#l_main .post .article-meta .new-meta-box .new-meta-item .notlink:hover p {
  color: var(--color-meta);
}
#l_main .post .article-meta .new-meta-box .new-meta-item:last-child {
  margin-right: 0;
}
#l_main .post .article-meta .new-meta-box .new-meta-item img,
#l_main .post .article-meta .new-meta-box .new-meta-item i {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
#l_main .post .article-meta .new-meta-box .new-meta-item i {
  margin-right: 4px;
  border-radius: 0;
  -webkit-border-radius: 0;
}
#l_main .post .article-meta .new-meta-box .new-meta-item i.fa-hashtag {
  margin-right: 2px;
}
#l_main .post .article-meta .new-meta-box .new-meta-item p,
#l_main .post .article-meta .new-meta-box .new-meta-item a {
  color: var(--color-meta);
  padding: 3px 0;
}
#l_main .post .article-meta .new-meta-box .new-meta-item a {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
}
#l_main .post .article-meta .new-meta-box .new-meta-item a img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
#l_main .post .article-meta .new-meta-box .new-meta-item a p {
  margin: 0;
  font-weight: normal;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
#l_main .post .article-meta .new-meta-box .new-meta-item a:hover {
  color: #ff5722;
}
#l_main .post .article-meta .new-meta-box .new-meta-item a:hover p {
  color: #ff5722;
}
#l_main .post .article-meta .new-meta-box .author img,
#l_main .post .article-meta .new-meta-box .author i {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
#l_main .post .article-meta .new-meta-box .author img {
  transform: translateY(-0.5px);
  -webkit-transform: translateY(-0.5px);
  -khtml-transform: translateY(-0.5px);
  -moz-transform: translateY(-0.5px);
  -o-transform: translateY(-0.5px);
  -ms-transform: translateY(-0.5px);
}
@media screen and (max-width: 500px) {
  #l_main .post .article-meta .new-meta-box .share {
    width: 100%;
    margin-top: 16px;
    background: var(--color-block);
    border-radius: 4px;
    -webkit-border-radius: 4px;
  }
}
#l_main .post .article-meta .new-meta-box .share-body {
  position: relative;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  margin: 0;
  padding: 0 2px;
}
#l_main .post .article-meta .new-meta-box .share-body a {
  padding: 0;
  margin: 0 1px;
}
#l_main .post .article-meta .new-meta-box .share-body a img {
  margin: 2px;
  height: 24px;
  width: auto;
  background: transparent;
}
@media screen and (max-width: 500px) {
  #l_main .post .article-meta .new-meta-box .share-body a img {
    height: 32px;
    margin: 8px;
  }
}
#l_main .post .article-meta .new-meta-box .share-body div.hoverbox div.target {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
  position: absolute;
  background: var(--color-card);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  padding: 8px;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  -khtml-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
}
#l_main .post .article-meta .new-meta-box .share-body div.hoverbox div.target img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin: 0;
  padding: 0;
  height: 128px;
  width: 128px;
  min-width: 128px;
}
#l_main .post .article-meta .new-meta-box .share-body div.hoverbox:hover div.target {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
}
@media screen and (max-width: 500px) {
  #l_main .post .article-meta .new-meta-box .share-body div.hoverbox div.target {
    position: absolute;
  }
}
#l_main .post span>img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
#l_main .post a img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
}
@media screen and (max-width: 768px) {
  #l_main {
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) and (max-width: 500px) {
  #l_main {
    width: 100%;
  }
}
.body-wrapper {
  position: relative;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: stretch;
}
@media screen and (min-width: 2048px) {
  .body-wrapper {
    max-width: 55vw;
  }
}
article#comments p[ct] {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.125rem;
  color: var(--color-text);
  font-weight: 600;
}
article#comments p[cst] {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 0.875rem;
}
article#comments #load-btns,
article#comments #loading-comments {
  text-align: center;
  margin: 16px 0;
}
article#comments #load-btns,
article#comments #loading-comments,
article#comments #load-btns a,
article#comments #loading-comments a,
article#comments #load-btns i,
article#comments #loading-comments i {
  line-height: 3em;
}
article#comments #load-btns a.load-comments,
article#comments #loading-comments a.load-comments {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  cursor: pointer;
  background: #44d7b6;
  color: #fff;
  padding-left: 48px;
  padding-right: 48px;
}
article#comments #load-btns a.load-comments:hover,
article#comments #loading-comments a.load-comments:hover {
  background: #ff5722;
}
.white-box {
  background: var(--color-card);
}
img {
  max-width: 100%;
}
img.lazyload:not(.placeholder) {
  transition: opacity 0.5s ease-out 0s;
  transition: filter 0.25s ease-out 0s;
  -webkit-transition: filter 0.25s ease-out 0s;
  -khtml-transition: filter 0.25s ease-out 0s;
  -moz-transition: filter 0.25s ease-out 0s;
  -o-transition: filter 0.25s ease-out 0s;
  -ms-transition: filter 0.25s ease-out 0s;
}
img.lazyload:not(.placeholder):not(.loaded) {
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
}
img.lazyload:not(.placeholder).loaded {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
}
img.lazyload:not(.placeholder):not(.loaded) {
  filter: blur(8px);
}
img.lazyload:not(.placeholder).loaded {
  filter: none;
}
.md >p {
  padding-top: 4px;
}
.md h1,
.md h2,
.md h3,
.md h4,
.md h5,
.md h6 {
  position: relative;
  pointer-events: none;
  margin-top: 0;
  font-weight: 500;
}
.md h1 >a,
.md h2 >a,
.md h3 >a,
.md h4 >a,
.md h5 >a,
.md h6 >a {
  color: inherit;
  pointer-events: auto;
}
.md h1 >a:hover,
.md h2 >a:hover,
.md h3 >a:hover,
.md h4 >a:hover,
.md h5 >a:hover,
.md h6 >a:hover {
  color: #ff5722;
}
.md h1:before,
.md h2:before,
.md h3:before,
.md h4:before,
.md h5:before,
.md h6:before {
  content: '';
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin-top: -48px;
  height: 96px;
  visibility: hidden;
  pointer-events: none;
}
.md h1:before,
.md h2:before {
  margin-top: -32px;
}
.md .article-meta+h1,
.md .article-meta+h2 {
  margin-top: -80px;
}
.md h3,
.md h4,
.md h5,
.md h6 {
  margin-bottom: 1em;
}
.md h3:before {
  margin-top: -56px;
}
.md h4:before {
  margin-top: -64px;
}
.md h5 {
  font-weight: bold;
}
.md h2+h3:before {
  margin-top: -80px;
}
.md ul,
.md ol {
  font-size: 0.9375rem;
  list-style: initial;
  padding-left: 8px;
  margin-left: 16px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.md ul ul,
.md ol ul,
.md ul ol,
.md ol ol {
  margin-top: 0;
  margin-bottom: 0;
}
.md ul li,
.md ol li {
  margin-top: 0px;
  margin-bottom: 0px;
}
.md ul li li,
.md ol li li {
  margin-top: 0;
  margin-bottom: 0;
}
.md ul li p,
.md ol li p {
  margin-top: 4px;
  margin-bottom: 0;
}
.md ul.task-list,
.md ol.task-list {
  padding-left: 0;
  margin-left: 4px;
}
.md ul.task-list li,
.md ol.task-list li {
  list-style: none;
}
.md ul.task-list li input,
.md ol.task-list li input {
  margin-right: 4px;
}
.md ul>li {
  list-style: initial;
}
.md ol>li {
  list-style: decimal;
}
.md .div-ori-link {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: center;
  margin: 4rem 0;
}
.md .ori-link {
  margin: auto;
  padding: 1em 3em;
  border: 1px solid #3dd9b6;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  color: #3dd9b6;
  font-weight: 500;
}
.md .ori-link:hover {
  color: #ff5722;
  border-color: #ff5722;
}
#l_main .prev-next {
  width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: baseline;
  color: var(--color-meta);
  margin: 0;
  font-weight: 600;
}
#l_main .prev-next .prev {
  text-align: left;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}
#l_main .prev-next .next {
  text-align: right;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}
#l_main .prev-next p {
  margin: 16px;
}
#l_main .prev-next section {
  color: var(--color-meta);
  padding: 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
#l_main .prev-next section:hover {
  color: #ff5722;
}
#l_main .article .prev-next {
  width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-content: flex-start;
  margin-top: 8px;
}
#l_main .article .prev-next >a {
  width: 100%;
  padding: 8px;
  color: var(--color-meta);
  background: var(--color-block);
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
#l_main .article .prev-next >a:hover {
  background: #ffeee8;
}
#l_main .article .prev-next >a:hover p.title {
  color: #ff5722;
}
#l_main .article .prev-next >a p {
  margin: 8px 0.5rem;
}
#l_main .article .prev-next >a p.title {
  font-weight: 600;
  font-size: 1rem;
}
#l_main .article .prev-next >a p.title >i {
  width: 1rem;
}
#l_main .article .prev-next >a p.content {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: justify;
  word-break: break-all;
}
#l_main .article .prev-next >a:only-child {
  margin-left: 0;
  margin-right: 0;
}
#l_main .article .prev-next .prev {
  margin-left: 0;
  margin-right: 8px;
}
#l_main .article .prev-next .prev p.title {
  text-align: left;
}
#l_main .article .prev-next .next {
  margin-left: 8px;
  margin-right: 0;
}
#l_main .article .prev-next .next p.title {
  text-align: right;
}
.article-title {
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 1.4;
}
.article-title a {
  color: var(--color-h1);
}
.article-title a:hover {
  color: #ff5722;
}
.article-title[pin] {
  margin-right: 36px;
}
.article-desc {
  word-break: break-word;
}
.post-v3 {
  overflow: hidden;
  text-align: justify;
}
.post-v3 .md {
  color: var(--color-p);
}
.post-v3 .pin {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  right: 24px;
  top: 24px;
  z-index: 1;
  pointer-events: none;
}
.meta-v3[line_style='solid'] {
  border-top: 1px solid rgba(68,68,68,0.1);
}
.meta-v3[line_style='dashed'] {
  border-top: 2px dashed rgba(68,68,68,0.1);
}
.meta-v3[line_style='dotted'] {
  border-top: 4px dotted rgba(68,68,68,0.1);
}
.meta-v3 {
  margin-top: 16px;
  padding-top: 12px;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  color: var(--color-meta);
}
.meta-v3 >div {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.meta-v3 time {
  font-size: 0.875rem;
}
.meta-v3 .category-link {
  font-size: 0.875rem;
  color: var(--color-meta);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.meta-v3 .category-link:hover {
  color: #ff5722;
}
.meta-v3 .readmore {
  font-weight: bold;
}
.meta-v3 .avatar {
  line-height: 0;
  margin-right: 0.75em;
}
.meta-v3 .avatar img {
  width: 24px;
  height: 24px;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -o-object-fit: cover;
     object-fit: cover;
}
.headimg-div {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin-left: -24px;
  margin-top: -24px;
  margin-bottom: 20px;
  width: calc(100% + 3 * 16px);
}
.headimg-div .headimg-a {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  overflow: hidden;
  height: 280px;
}
@media screen and (max-width: 768px) {
  .headimg-div .headimg-a {
    height: 250px;
  }
}
@media screen and (max-width: 500px) {
  .headimg-div .headimg-a {
    height: 220px;
  }
}
@media screen and (max-width: 425px) {
  .headimg-div .headimg-a {
    height: 190px;
  }
}
.headimg-div .headimg {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 3s ease-out;
  -webkit-transition: transform 3s ease-out;
  -khtml-transition: transform 3s ease-out;
  -moz-transition: transform 3s ease-out;
  -o-transition: transform 3s ease-out;
  -ms-transition: transform 3s ease-out;
}
.headimg-div .headimg:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -khtml-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
.headimg-div .headimg.lazyload:not(.placeholder) {
  transition: transform 3s ease-out, opacity 0.5s ease-out;
  -webkit-transition: transform 3s ease-out, opacity 0.5s ease-out;
  -khtml-transition: transform 3s ease-out, opacity 0.5s ease-out;
  -moz-transition: transform 3s ease-out, opacity 0.5s ease-out;
  -o-transition: transform 3s ease-out, opacity 0.5s ease-out;
  -ms-transition: transform 3s ease-out, opacity 0.5s ease-out;
}
.headimg-div .headimg.lazyload:not(.placeholder):not(.loaded) {
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
}
.headimg-div .headimg.lazyload:not(.placeholder).loaded {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
}
#u-search .modal {
  position: fixed;
  height: 80%;
  width: 100%;
  max-width: 640px;
  left: 50%;
  top: 0;
  margin: 64px 0px 0px -320px;
  background: var(--color-card);
  z-index: 3;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  overflow: hidden;
}
#u-search .modal .search-icon,
#u-search .modal #resule-hits-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
#u-search .modal .search-icon i,
#u-search .modal #resule-hits-empty i {
  font-size: 8em;
  color: #e8e8e8;
  margin-bottom: 10px;
}
@media screen and (max-width: 680px) {
  #u-search .modal {
    box-shadow: none;
    -webkit-box-shadow: none;
    max-width: none;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
  }
}
#u-search .modal .modal-header {
  position: relative;
  width: 100%;
  height: 64px;
  z-index: 3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: $fontsize;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  background: #fff;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
@media screen and (max-width: 680px) {
  #u-search .modal .modal-header {
    border-radius: 0;
    -webkit-border-radius: 0;
    padding: 0px;
  }
}
#u-search .modal .modal-header .btn-close {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: absolute;
  width: 55px;
  height: 64px;
  top: 0;
  right: 0;
  color: #3dd9b6;
  cursor: pointer;
  text-align: center;
  line-height: 64px;
  vertical-align: middle;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  z-index: 2;
}
#u-search .modal .modal-header .btn-close:hover {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -khtml-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}
#u-search .modal .modal-header #u-search-modal-form {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}
#u-search .modal .modal-header #u-search-modal-form input {
  color: var(--color-text);
}
#u-search .modal .modal-header #u-search-modal-form #u-search-modal-input {
  margin: 16px 50px;
  padding: 0 8px;
  width: calc(100% - 100px - 16px);
  line-height: 2rem;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  vertical-align: middle;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: transparent;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
@media screen and (max-width: 680px) {
  #u-search .modal .modal-header #u-search-modal-form #u-search-modal-input {
    padding: 0;
  }
}
#u-search .modal .modal-header #u-search-modal-form #u-search-modal-input:focus {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
#u-search .modal .modal-header #u-search-modal-btn-submit {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 5px;
  padding-top: 2px;
  background: transparent;
  border: none;
  width: 50px;
  height: 64px;
  vertical-align: middle;
  color: #3dd9b6;
  z-index: 2;
}
#u-search .modal .modal-body {
  position: absolute;
  padding: 16px;
  width: 100%;
  height: calc(100% - 64px);
  top: 64px;
  left: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: var(--color-card);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
#u-search .modal .modal-body::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
#u-search .modal .modal-body::-webkit-scrollbar-track-piece {
  background: transparent;
}
#u-search .modal .modal-body::-webkit-scrollbar-thumb {
  background: #3dd9b6;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
#u-search .modal .modal-body::-webkit-scrollbar-thumb:hover {
  background: #ff5722;
}
#u-search .modal .modal-body .modal-results {
  list-style: none;
}
#u-search .modal .modal-body .modal-results .result {
  position: relative;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  padding: 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
#u-search .modal .modal-body .modal-results .result b[mark] {
  color: #25be9c;
  text-decoration: underline;
  font-size: 120%;
  background-color: #ffe600;
}
#u-search .modal .modal-body .modal-results .result:hover {
  background: var(--color-site-bg);
}
#u-search .modal .modal-body .modal-results .result:hover .title {
  color: var(--color-list-hl);
}
#u-search .modal .modal-body .modal-results .result .title {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  max-width: 100%;
  color: var(--color-list);
  font-weight: bold;
  padding: 1px;
  margin-bottom: 2px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125rem;
}
#u-search .modal .modal-body .modal-results .result .digest {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  white-space: inherit;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  color: var(--color-meta);
}
#u-search .modal .modal-body .modal-results .result .icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
  font-size: 11px;
  color: var(--color-meta);
}
#u-search .modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 1;
}
#l_side {
  width: 240px;
  float: right;
  position: relative;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  #l_side {
    width: 100%;
  }
}
#l_side .widget {
  overflow: hidden;
}
#l_side .widget.sticky {
  position: sticky;
  top: 80px;
  z-index: 1;
}
#l_side .widget >.content {
  max-height: 200vh;
  overflow: auto;
  text-align: justify;
  font-size: 0.875rem;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  #l_side .widget >.content {
    max-height: none;
  }
}
#l_side .widget >.content::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
#l_side .widget >.content::-webkit-scrollbar-track-piece {
  background: transparent;
}
#l_side .widget >.content::-webkit-scrollbar-thumb {
  background: #3dd9b6;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
#l_side .widget >.content::-webkit-scrollbar-thumb:hover {
  background: #ff5722;
}
.widget {
  z-index: 0;
  background: var(--color-card);
  margin-bottom: 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  width: 100%;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
.widget ul li,
.widget ol li {
  margin-top: 0;
  margin-bottom: 0;
}
.widget.desktop {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
@media screen and (max-width: 768px) {
  .widget {
    display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
    display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
    display: none !important;
  }
  .widget.mobile {
    display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
    display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
    display: block !important;
  }
}
.widget header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: calc(16px - 2px);
  font-weight: bold;
  font-size: 0.875rem;
  padding-bottom: 0;
}
.widget header,
.widget header a {
  color: var(--color-meta);
}
.widget header >a:hover {
  color: #ff5722;
}
.widget header span.name {
  margin-left: 8px;
}
.widget >.content {
  padding: 8px 0;
}
.widget >.content p {
  margin-top: 1em;
  margin-bottom: 1em;
}
.widget >.content p:first-child {
  margin-top: 0.5em;
}
.widget >.content ul>li a {
  color: var(--color-meta);
  padding: 0 16px;
  padding-left: 12px;
  line-height: 2;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-content: center;
  border-left: 2px solid transparent;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.widget >.content ul.entry,
.widget >.content ul.popular-posts {
  list-style: none;
}
.widget >.content ul.entry a,
.widget >.content ul.popular-posts a {
  color: var(--color-list);
}
.widget >.content ul.entry a .name,
.widget >.content ul.popular-posts a .name {
  flex: auto 0;
}
.widget >.content ul.entry a .badge,
.widget >.content ul.popular-posts a .badge {
  flex: none 0;
  font-weight: normal;
  font-size: 0.875rem;
  color: rgba(68,68,68,0.7);
}
.widget >.content ul.entry a.active,
.widget >.content ul.popular-posts a.active {
  border-left: 2px solid #3dd9b6;
  color: var(--color-list-hl);
}
.widget >.content ul.entry a.active .badge,
.widget >.content ul.popular-posts a.active .badge {
  color: rgba(61,217,182,0.9);
}
.widget >.content ul.entry a.child,
.widget >.content ul.popular-posts a.child {
  padding-left: 32px;
}
.widget >.content ul.entry a:hover,
.widget >.content ul.popular-posts a:hover {
  border-left: 2px solid #3dd9b6;
  color: var(--color-list-hl);
  background: var(--color-site-bg);
}
.widget.blogger {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.widget.blogger .content {
  padding: 0;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.widget.blogger .content >.avatar {
  align-self: center;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  line-height: 0;
}
.widget.blogger .content >.avatar.circle {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  width: 128px;
  height: 128px;
  margin-top: 32px;
  margin-bottom: 1em;
}
.widget.blogger .content >.avatar.circle img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
@media screen and (max-width: 768px) {
  .widget.blogger .content >.avatar {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    border: 2px solid #fff;
  }
  .widget.blogger .content >.avatar img {
    display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
    display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
  }
}
.widget.blogger .content .text :first-child {
  margin-top: 16px;
}
.widget.blogger .content h2 {
  text-align: center;
  font-weight: bold;
  margin: 8px;
}
@media screen and (max-width: 768px) {
  .widget.blogger .content h2 {
    margin: 8px;
  }
}
.widget.blogger .content p {
  text-align: center;
  font-weight: bold;
  margin: 8px 8px 0 8px;
  empty-cells: hide;
}
.widget.blogger .content .social-wrapper {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 4px 4px;
}
.widget.blogger .content .social-wrapper a {
  color: var(--color-meta);
  padding: 0;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.widget.blogger .content .social-wrapper a:hover {
  color: #ff5722;
}
.widget.blogger .content .social-wrapper a.social {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
}
.widget.blogger .content .social-wrapper a.social:hover {
  background: #ebfbf7;
  color: #3dd9b6;
}
@media screen and (max-width: 768px) {
  .widget.blogger .content .social-wrapper {
    justify-content: center;
    -webkit-justify-content: center;
    -khtml-justify-content: center;
    -moz-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
    display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .widget.blogger {
    box-shadow: none;
    -webkit-box-shadow: none;
    background: transparent !important;
    margin-top: 32px;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    color: var(--color-site-inner);
  }
}
.widget.text .content {
  padding: 4px 16px;
}
.widget.text .content,
.widget.text .content p {
  font-size: 0.875rem;
  word-break: break-all;
}
.widget.text .content a {
  color: #2092ec;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.widget.text .content a:hover {
  color: #ff5722;
}
.widget.text .content a:active {
  color: #d93400;
}
.widget.list .content {
  padding: 8px 0;
}
.widget.list .content a {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 6px;
  padding-bottom: 6px;
}
.widget.list .content a img,
.widget.list .content a i {
  margin-right: 4px;
}
.widget.list .content a i {
  margin-left: 1px;
}
.widget.list .content a img {
  vertical-align: middle;
  height: 20px;
  width: 20px;
  margin-bottom: 4px;
}
.widget.list .content a img#round {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.widget.grid .content .grid {
  border: none;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -khtml-justify-content: space-around;
  -moz-justify-content: space-around;
  -o-justify-content: space-around;
  -ms-justify-content: space-around;
  padding: 4px 16px;
}
.widget.grid .content .grid a {
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 0;
  padding: 4px 8px;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.78125rem;
  font-weight: bold;
  color: rgba(68,68,68,0.7);
  line-height: 1.5;
  word-wrap: break-word;
}
.widget.grid .content .grid a i {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-size: 1.8em;
}
.widget.grid .content .grid a img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  vertical-align: middle;
  margin-bottom: 4px;
}
.widget.grid .content .grid a img#round {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.widget.grid .content .grid a.active {
  color: var(--color-list-hl);
  background: var(--color-site-bg);
}
.widget.grid .content .grid a:hover {
  color: var(--color-list-hl);
  background: var(--color-site-bg);
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
.widget.grid .content .grid.fixed a {
  width: calc(100%/3 - 0 * 16px);
}
@media screen and (max-width: 768px) {
  .widget.grid .content .grid.fixed a {
    width: calc(100%/6 - 0 * 16px);
  }
}
@media screen and (max-width: 500px) {
  .widget.grid .content .grid.fixed a {
    width: calc(100%/5 - 0 * 16px);
  }
}
@media screen and (max-width: 425px) {
  .widget.grid .content .grid.fixed a {
    width: calc(100%/4 - 0 * 16px);
  }
}
@media screen and (max-width: 375px) {
  .widget.grid .content .grid.fixed a {
    width: calc(100%/3 - 0 * 16px);
  }
}
.widget.tagcloud .content {
  text-align: justify;
  padding: 8px 16px;
}
.widget.tagcloud .content a {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  line-height: 1.2em;
  margin: 4px 0;
  border-bottom: 1px solid transparent;
}
.widget.tagcloud .content a:hover {
  color: #ff5722 !important;
  border-bottom: 1px solid #ff5722;
}
.widget.related_posts .content {
  font-weight: bold;
}
.widget.related_posts .content ul {
  margin-top: 8px;
  margin-bottom: 8px;
}
.widget.related_posts .content h3 {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
}
.widget.related_posts .content h3 a {
  line-height: inherit;
  padding-top: 4px;
  padding-bottom: 4px;
}
.widget.qrcode .content {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -khtml-justify-content: space-around;
  -moz-justify-content: space-around;
  -o-justify-content: space-around;
  -ms-justify-content: space-around;
  padding-left: 16px;
  padding-right: 16px;
}
.widget.qrcode .content,
.widget.qrcode .content img {
  margin-bottom: 4px;
}
#l_side>.widget.page >.content {
  padding-top: 0;
  padding-left: 12px;
  padding-right: 12px;
}
.webinfo {
  padding: 0.2rem 1rem;
}
.webinfo .webinfo-item {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  padding: 4px 0 0;
}
.webinfo .webinfo-item div:first-child {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
.webinfo .webinfo-item div:last-child {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  float: right;
}
.widget-last {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block !important;
}
.widget-last .item {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  font-size: 0.8rem;
}
.widget-last .item-title {
  width: 100%;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-sticky {
  position: sticky;
  top: 80px;
  z-index: 10;
}
.snackbar-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #3dd9b6;
  padding: 16px;
  z-index: 2;
}
.snackbar-content {
  max-width: 1080px;
  margin: 16px auto;
}
@media screen and (max-width: 1080px) {
  .snackbar-content {
    max-width: 100%;
  }
}
.snackbar-content p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}
.snackbar-content .title {
  font-size: 1.5rem;
  font-weight: 600;
}
.snackbar-content .action {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin: 1.5rem -4px;
}
.snackbar-content .action a {
  margin: 4px;
  cursor: pointer;
  color: #fff;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  padding: 0.4em 2em;
  font-weight: 600;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border: 1px solid #fff;
}
.snackbar-content .action a:hover {
  color: #3dd9b6;
  background: #fff;
}
.snackbar-wrap[theme='warning'] {
  background: #f7e751;
}
.snackbar-wrap[theme='warning'] .snackbar-content p {
  color: #000;
}
.snackbar-wrap[theme='warning'] .snackbar-content a {
  color: #000;
  border-color: #000;
}
.snackbar-wrap[theme='warning'] .snackbar-content a:hover {
  color: #f7e751;
  background: #000;
}
#l_side .toc-wrapper {
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  line-height: 1.6;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
#l_side .toc-wrapper header {
  position: sticky;
  width: 100%;
  top: 0;
  padding-bottom: 4px;
}
#l_side .toc-wrapper .content {
  max-height: calc(100vh - 144px);
}
#l_side .toc-wrapper .content a {
  border-left: 2px solid transparent;
}
#l_side .toc-wrapper .content a.active {
  color: var(--color-list-hl);
  border-left: 2px solid #3dd9b6;
}
#l_side .toc-wrapper .content a:hover {
  color: var(--color-list-hl);
  background: var(--color-site-bg);
  border-left: 2px solid #3dd9b6;
}
@media screen and (max-width: 768px) {
  #l_side .toc-wrapper {
    z-index: 1001;
    position: fixed;
    max-height: 1000px;
    width: auto;
    max-width: calc(100% - 2 * 16px);
    top: 48px;
    right: 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1);
    border: 1px solid #e7e7e7;
    visibility: hidden;
    transform: scale(0, 0);
    -webkit-transform: scale(0, 0);
    -khtml-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    transform-origin: right top;
    -webkit-transform-origin: right top;
    -khtml-transform-origin: right top;
    -moz-transform-origin: right top;
    -o-transform-origin: right top;
    -ms-transform-origin: right top;
  }
  #l_side .toc-wrapper.active {
    visibility: visible;
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -khtml-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
  }
}
@media screen and (max-width: 375px) {
  #l_side .toc-wrapper {
    right: 0;
  }
}
#l_side .toc-wrapper a {
  padding-left: 8px;
  color: var(--color-meta);
  font-size: 0.875rem;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
#l_side .toc-wrapper ul .toc-child a,
#l_side .toc-wrapper ol .toc-child a {
  font-weight: normal;
}
#l_side .toc-wrapper ul .toc-item.toc-level-1 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-1 .toc-child a {
  padding-left: 12.8px;
}
#l_side .toc-wrapper ul .toc-item.toc-level-2 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-2 .toc-child a {
  padding-left: 25.6px;
}
#l_side .toc-wrapper ul .toc-item.toc-level-3 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-3 .toc-child a {
  padding-left: 38.4px;
}
#l_side .toc-wrapper ul .toc-item.toc-level-4 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-4 .toc-child a {
  padding-left: 51.2px;
}
#l_side .toc-wrapper ul .toc-item.toc-level-5 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-5 .toc-child a {
  padding-left: 64px;
}
#l_side .toc-wrapper ul .toc-item.toc-level-6 .toc-child a,
#l_side .toc-wrapper ol .toc-item.toc-level-6 .toc-child a {
  padding-left: 76.8px;
}
#l_side .toc-wrapper ul li,
#l_side .toc-wrapper ol li {
  width: auto;
  text-align: left;
}
#l_side .toc-wrapper ul li a,
#l_side .toc-wrapper ol li a {
  padding: 0 8px 0 11px;
  font-weight: bold;
  width: 100%;
}
#l_side .toc-wrapper:empty {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
#l_side .toc-wrapper .toc-child {
  font-size: 1rem;
  overflow: hidden;
  transition: max-height 0.6s ease-in;
  -webkit-transition: max-height 0.6s ease-in;
  -khtml-transition: max-height 0.6s ease-in;
  -moz-transition: max-height 0.6s ease-in;
  -o-transition: max-height 0.6s ease-in;
  -ms-transition: max-height 0.6s ease-in;
  max-height: 0;
}
#l_side .toc-wrapper .toc-item.active>.toc-link {
  color: var(--color-list-hl);
  border-left: 2px solid #3dd9b6;
}
#l_side .toc-wrapper .toc-item.active>.toc-child {
  max-height: 1000px;
}
.md .video-wrap {
  margin: 1.5rem auto;
}
.article span.btn,
.md span.btn {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
}
.article span.btn a,
.md span.btn a {
  text-decoration: none;
  border-bottom: none;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  padding: 3px 4px 2px 4px;
  margin: 2px;
  line-height: 1.1;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.article span.btn a i,
.md span.btn a i {
  margin-right: 2px;
}
.article span.btn a:not([href]),
.md span.btn a:not([href]) {
  opacity: 0.5;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
}
.article span.btn a:not([href]):hover,
.md span.btn a:not([href]):hover {
  cursor: $not-allowed, not-allowed;
}
.article span.btn a,
.md span.btn a,
.article span.btn.regular a,
.md span.btn.regular a {
  color: #44d7b6;
  border: 1px solid #44d7b6;
}
.article span.btn a:hover,
.md span.btn a:hover,
.article span.btn.regular a:hover,
.md span.btn.regular a:hover {
  color: #ff5722;
  border-color: #ff5722;
}
.article span.btn a i,
.md span.btn a i,
.article span.btn.regular a i,
.md span.btn.regular a i {
  margin-right: 4px;
}
.article span.btn.regular a,
.md span.btn.regular a,
.article span.btn.solid a,
.md span.btn.solid a {
  padding: 8px 12px;
}
.article span.btn.solid a,
.md span.btn.solid a {
  border: none;
  background: #44d7b6;
  color: #fff;
}
.article span.btn.solid a:hover,
.md span.btn.solid a:hover {
  color: #fff;
  background: #ff5722;
}
.article span.btn.large a,
.md span.btn.large a {
  font-size: 1rem;
  padding: 12px 36px;
}
.article span.btn.large a i,
.md span.btn.large a i {
  margin-right: 8px;
}
.article span.btn.center,
.md span.btn.center {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: center;
}
.article div.btns,
.md div.btns {
  margin: 0 -8px;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: flex-start;
  overflow: visible;
  line-height: 1.8;
}
.article div.btns,
.md div.btns,
.article div.btns p,
.md div.btns p,
.article div.btns a,
.md div.btns a {
  font-size: 0.8125rem;
  color: var(--color-p);
}
.article div.btns b,
.md div.btns b {
  font-size: 0.875rem;
}
.article div.btns.wide>a,
.md div.btns.wide>a {
  padding-left: 32px;
  padding-right: 32px;
}
.article div.btns.fill>a,
.md div.btns.fill>a {
  flex-grow: 1;
  width: auto;
}
.article div.btns.around,
.md div.btns.around {
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -khtml-justify-content: space-around;
  -moz-justify-content: space-around;
  -o-justify-content: space-around;
  -ms-justify-content: space-around;
}
.article div.btns.center,
.md div.btns.center {
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
}
.article div.btns.grid2>a,
.md div.btns.grid2>a {
  width: calc(100%/2 - 16px);
}
@media screen and (max-width: 1024px) {
  .article div.btns.grid2>a,
  .md div.btns.grid2>a {
    width: calc(100%/2 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .article div.btns.grid2>a,
  .md div.btns.grid2>a {
    width: calc(100%/2 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .article div.btns.grid2>a,
  .md div.btns.grid2>a {
    width: calc(100%/1 - 16px);
  }
}
.article div.btns.grid3>a,
.md div.btns.grid3>a {
  width: calc(100%/3 - 16px);
}
@media screen and (max-width: 1024px) {
  .article div.btns.grid3>a,
  .md div.btns.grid3>a {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .article div.btns.grid3>a,
  .md div.btns.grid3>a {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .article div.btns.grid3>a,
  .md div.btns.grid3>a {
    width: calc(100%/1 - 16px);
  }
}
.article div.btns.grid4>a,
.md div.btns.grid4>a {
  width: calc(100%/4 - 16px);
}
@media screen and (max-width: 1024px) {
  .article div.btns.grid4>a,
  .md div.btns.grid4>a {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .article div.btns.grid4>a,
  .md div.btns.grid4>a {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .article div.btns.grid4>a,
  .md div.btns.grid4>a {
    width: calc(100%/2 - 16px);
  }
}
.article div.btns.grid5>a,
.md div.btns.grid5>a {
  width: calc(100%/5 - 16px);
}
@media screen and (max-width: 1024px) {
  .article div.btns.grid5>a,
  .md div.btns.grid5>a {
    width: calc(100%/4 - 16px);
  }
}
@media screen and (max-width: 768px) {
  .article div.btns.grid5>a,
  .md div.btns.grid5>a {
    width: calc(100%/3 - 16px);
  }
}
@media screen and (max-width: 500px) {
  .article div.btns.grid5>a,
  .md div.btns.grid5>a {
    width: calc(100%/2 - 16px);
  }
}
.article div.btns a,
.md div.btns a {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  text-decoration: none;
  border-bottom: none;
  margin: 8px;
  margin-top: calc(1.25 * 16px + 32px);
  min-width: 120px;
  font-weight: bold;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  text-align: center;
  background: var(--color-block);
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.article div.btns a>img:first-child,
.md div.btns a>img:first-child,
.article div.btns a>i:first-child,
.md div.btns a>i:first-child {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  height: 64px;
  width: 64px;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  margin: 16px 8px 4px 8px;
  margin-top: calc(-1.25 * 16px - 32px);
  border: 2px solid var(--color-card);
  background: #fff;
  line-height: 60px;
  font-size: 28px;
}
.article div.btns a>img:first-child.auto,
.md div.btns a>img:first-child.auto,
.article div.btns a>i:first-child.auto,
.md div.btns a>i:first-child.auto {
  width: auto;
}
.article div.btns a>i:first-child,
.md div.btns a>i:first-child {
  color: #fff;
  background: #3dd9b6;
}
.article div.btns a p,
.md div.btns a p,
.article div.btns a b,
.md div.btns a b {
  margin: 0.25em;
  font-weight: normal;
  line-height: 1.25;
  word-wrap: break-word;
}
.article div.btns a b,
.md div.btns a b {
  font-weight: bold;
  line-height: 1.3;
}
.article div.btns a img,
.md div.btns a img {
  margin: 0.4em auto;
}
.article div.btns a:not([href]),
.md div.btns a:not([href]) {
  cursor: default;
  color: inherit;
}
.article div.btns a[href]:hover,
.md div.btns a[href]:hover {
  background: rgba(255,87,34,0.15);
}
.article div.btns a[href]:hover,
.md div.btns a[href]:hover,
.article div.btns a[href]:hover b,
.md div.btns a[href]:hover b {
  color: #ff5722;
}
.article div.btns a[href]:hover>img:first-child,
.md div.btns a[href]:hover>img:first-child,
.article div.btns a[href]:hover>i:first-child,
.md div.btns a[href]:hover>i:first-child {
  transform: scale(1.1) translateY(-8px);
  -webkit-transform: scale(1.1) translateY(-8px);
  -khtml-transform: scale(1.1) translateY(-8px);
  -moz-transform: scale(1.1) translateY(-8px);
  -o-transform: scale(1.1) translateY(-8px);
  -ms-transform: scale(1.1) translateY(-8px);
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1);
}
.article div.btns a[href]:hover>i:first-child,
.md div.btns a[href]:hover>i:first-child {
  background: #ff5722;
}
.article div.btns.circle a>img:first-child,
.md div.btns.circle a>img:first-child,
.article div.btns.circle a>i:first-child,
.md div.btns.circle a>i:first-child {
  border-radius: 32px;
  -webkit-border-radius: 32px;
}
.article div.btns.rounded a>img:first-child,
.md div.btns.rounded a>img:first-child,
.article div.btns.rounded a>i:first-child,
.md div.btns.rounded a>i:first-child {
  border-radius: 16px;
  -webkit-border-radius: 16px;
}
.md .checkbox {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
/* Checkbox */
/* Radio */
/* Colors */
}
.md .checkbox+.checkbox {
  margin-top: -1em;
}
.md .checkbox input {
  transform: translate(0, -1px);
  -webkit-transform: translate(0, -1px);
  -khtml-transform: translate(0, -1px);
  -moz-transform: translate(0, -1px);
  -o-transform: translate(0, -1px);
  -ms-transform: translate(0, -1px);
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 16px;
  width: 16px;
  transition: all 0.15s ease-out 0s;
  -webkit-transition: all 0.15s ease-out 0s;
  -khtml-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  -o-transition: all 0.15s ease-out 0s;
  -ms-transition: all 0.15s ease-out 0s;
  cursor: pointer;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  outline: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  flex-shrink: 0;
  margin-right: 8px;
  pointer-events: none;
}
.md .checkbox input[type=checkbox]:before,
.md .checkbox input[type=checkbox]:after {
  position: absolute;
  content: "";
  background: #fff;
}
.md .checkbox input[type=checkbox]:before {
  left: 1px;
  top: 5px;
  width: 0px;
  height: 2px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -khtml-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
.md .checkbox input[type=checkbox]:after {
  right: 7px;
  bottom: 3px;
  width: 2px;
  height: 0px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -khtml-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -khtml-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -khtml-transition-delay: 0.25s;
  -moz-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
}
.md .checkbox input[type=checkbox]:checked:before {
  left: 0px;
  top: 7px;
  width: 6px;
  height: 2px;
}
.md .checkbox input[type=checkbox]:checked:after {
  right: 3px;
  bottom: 1px;
  width: 2px;
  height: 10px;
}
.md .checkbox.minus input[type=checkbox]:before {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0px;
  height: 2px;
}
.md .checkbox.minus input[type=checkbox]:after {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0px;
  height: 2px;
}
.md .checkbox.minus input[type=checkbox]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.md .checkbox.minus input[type=checkbox]:checked:after {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.md .checkbox.plus input[type=checkbox]:before {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  left: 1px;
  top: 5px;
  width: 0px;
  height: 2px;
}
.md .checkbox.plus input[type=checkbox]:after {
  transform: rotate(0);
  -webkit-transform: rotate(0);
  -khtml-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  left: 5px;
  top: 1px;
  width: 2px;
  height: 0px;
}
.md .checkbox.plus input[type=checkbox]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.md .checkbox.plus input[type=checkbox]:checked:after {
  left: 5px;
  top: 1px;
  width: 2px;
  height: 10px;
}
.md .checkbox.times input[type=checkbox]:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -khtml-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  left: 3px;
  top: 1px;
  width: 0px;
  height: 2px;
}
.md .checkbox.times input[type=checkbox]:after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -khtml-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  right: 3px;
  top: 1px;
  width: 0px;
  height: 2px;
}
.md .checkbox.times input[type=checkbox]:checked:before {
  left: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.md .checkbox.times input[type=checkbox]:checked:after {
  right: 1px;
  top: 5px;
  width: 10px;
  height: 2px;
}
.md .checkbox input[type=radio] {
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.md .checkbox input[type=radio]:before {
  content: "";
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 2px;
  transform: scale(0);
  -webkit-transform: scale(0);
  -khtml-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -khtml-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
}
.md .checkbox input[type=radio]:checked:before {
  transform: scale(1);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
}
.md .checkbox input {
  border: 2px solid #3dd9b6;
}
.md .checkbox input[type=checkbox]:checked {
  background: #3dd9b6;
}
.md .checkbox input[type=radio]:checked:before {
  background: #3dd9b6;
}
.md .checkbox.red input {
  border-color: #fe5f58;
}
.md .checkbox.red input[type=checkbox]:checked {
  background: #fe5f58;
}
.md .checkbox.red input[type=radio]:checked:before {
  background: #fe5f58;
}
.md .checkbox.green input {
  border-color: #3dc550;
}
.md .checkbox.green input[type=checkbox]:checked {
  background: #3dc550;
}
.md .checkbox.green input[type=radio]:checked:before {
  background: #3dc550;
}
.md .checkbox.yellow input {
  border-color: #ffbd2b;
}
.md .checkbox.yellow input[type=checkbox]:checked {
  background: #ffbd2b;
}
.md .checkbox.yellow input[type=radio]:checked:before {
  background: #ffbd2b;
}
.md .checkbox.cyan input {
  border-color: #1bcdfc;
}
.md .checkbox.cyan input[type=checkbox]:checked {
  background: #1bcdfc;
}
.md .checkbox.cyan input[type=radio]:checked:before {
  background: #1bcdfc;
}
.md .checkbox.blue input {
  border-color: #2196f3;
}
.md .checkbox.blue input[type=checkbox]:checked {
  background: #2196f3;
}
.md .checkbox.blue input[type=radio]:checked:before {
  background: #2196f3;
}
article .checkbox p {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dropmenu-wrapper {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
div.dropmenu {
  position: relative;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  color: #2092ec;
}
div.dropmenu:hover {
  color: inherit;
}
div.dropmenu:hover >ul {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  left: 0;
  margin-left: 0;
  margin-top: 0px;
}
div.dropmenu ul>li {
  list-style: none;
}
div.dropmenu ul>li >a:hover {
  text-decoration: none !important;
}
div.dropmenu .list-v .list-v {
  left: calc(100% - 0.5 * 16px);
}
div.gallery {
  margin: 1em 0;
  overflow: hidden;
}
div.gallery+.gallery {
  margin-top: -1em;
}
div.gallery >.fancybox,
div.gallery >p>.fancybox {
  margin: 1px;
  padding: 0;
  position: relative;
}
div.gallery >.fancybox a,
div.gallery >p>.fancybox a {
  height: 100%;
  width: 100%;
}
div.gallery >.fancybox img,
div.gallery >p>.fancybox img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
div.gallery >.fancybox .image-caption,
div.gallery >p>.fancybox .image-caption {
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -khtml-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -khtml-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  pointer-events: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  background: rgba(0,0,0,0.3);
  color: #fff;
}
div.gallery >.fancybox .image-caption:empty,
div.gallery >p>.fancybox .image-caption:empty {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
div.gallery >.fancybox:hover .image-caption,
div.gallery >p>.fancybox:hover .image-caption {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -khtml-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}
div.gallery,
div.gallery >p {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -khtml-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  padding: 0 !important;
  align-items: stretch;
}
div.gallery[col] {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
}
div.gallery[col='2']>.fancybox {
  width: calc(50% - 2 * 1px);
}
div.gallery[col='3']>.fancybox {
  width: calc(33.33% - 2 * 1px);
}
div.gallery[col='4']>.fancybox {
  width: calc(25% - 2 * 1px);
}
div.gallery[col='5']>.fancybox {
  width: calc(20% - 2 * 1px);
}
div.gallery[col='6']>.fancybox {
  width: calc(16.66% - 2 * 1px);
}
div.gallery[col='7']>.fancybox {
  width: calc(14.2857% - 2 * 1px);
}
div.gallery[col='8']>.fancybox {
  width: calc(12.5% - 2 * 1px);
}
div.gallery >p {
  margin: 0;
}
div.gallery.left,
div.gallery.left>p {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
}
div.gallery.center,
div.gallery.center>p {
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
}
div.gallery.right,
div.gallery.right>p {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -khtml-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -o-justify-content: flex-end;
  -ms-justify-content: flex-end;
}
div.gallery.stretch,
div.gallery.stretch>p {
  align-items: stretch;
}
.fancybox-container .fancybox-stage {
  cursor: zoom-out;
}
div.fancybox {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -khtml-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  padding: 0 !important;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
@media screen and (max-width: 500px) {
  div.fancybox {
    border-radius: 1px;
    -webkit-border-radius: 1px;
  }
}
div.fancybox a {
  line-height: 0;
  margin: 0 auto;
  align-items: stretch;
}
div.fancybox .gallery {
  overflow: hidden;
}
div.fancybox .image-caption {
  font-size: 0.8125rem;
  padding-top: 0.5em;
  padding-bottom: 1em;
  color: var(--color-meta);
}
div.fancybox .image-caption:empty {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
details {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  padding: 16px;
  margin: 1em 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  background: var(--color-card);
  font-size: 0.9375rem;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  border: 1px solid var(--color-block);
}
details summary {
  cursor: pointer;
  padding: 16px;
  margin: -16px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  color: rgba(68,68,68,0.7);
  font-size: 0.875rem;
  font-weight: bold;
  position: relative;
  line-height: normal;
}
details summary > p,
details summary > h1,
details summary > h2,
details summary > h3,
details summary > h4,
details summary > h5,
details summary > h6 {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline;
  border-bottom: none !important;
}
details summary:hover {
  color: var(--color-p);
}
details summary:hover:after {
  position: absolute;
  content: '+';
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  right: 16px;
}
details >summary {
  background: var(--color-block);
}
details[blue] {
  border-color: #e8f4fd;
}
details[blue] >summary {
  background: #e8f4fd;
}
details[cyan] {
  border-color: #e8fafe;
}
details[cyan] >summary {
  background: #e8fafe;
}
details[green] {
  border-color: #ebf9ed;
}
details[green] >summary {
  background: #ebf9ed;
}
details[yellow] {
  border-color: #fff8e9;
}
details[yellow] >summary {
  background: #fff8e9;
}
details[red] {
  border-color: #feefee;
}
details[red] >summary {
  background: #feefee;
}
details[open] {
  border-color: rgba(68,68,68,0.2);
}
details[open] >summary {
  border-bottom: 1px solid rgba(68,68,68,0.2);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
details[open][blue] {
  border-color: rgba(33,150,243,0.3);
}
details[open][blue] >summary {
  border-bottom-color: rgba(33,150,243,0.3);
}
details[open][cyan] {
  border-color: rgba(27,205,252,0.3);
}
details[open][cyan] >summary {
  border-bottom-color: rgba(27,205,252,0.3);
}
details[open][green] {
  border-color: rgba(61,197,80,0.3);
}
details[open][green] >summary {
  border-bottom-color: rgba(61,197,80,0.3);
}
details[open][yellow] {
  border-color: rgba(255,189,43,0.3);
}
details[open][yellow] >summary {
  border-bottom-color: rgba(255,189,43,0.3);
}
details[open][red] {
  border-color: rgba(254,95,88,0.3);
}
details[open][red] >summary {
  border-bottom-color: rgba(254,95,88,0.3);
}
details[open] >summary {
  color: #444;
  margin-bottom: 0;
}
details[open] >summary:hover:after {
  content: '-';
}
details[open] >div.content {
  padding: 16px;
  margin: -16px;
  margin-top: 0;
}
details[open] >div.content p>a:hover {
  text-decoration: underline;
}
details[open] >div.content > p:first-child,
details[open] >div.content > .tabs:first-child,
details[open] >div.content > ul:first-child,
details[open] >div.content > ol:first-child,
details[open] >div.content > .highlight:first-child,
details[open] >div.content > .note:first-child,
details[open] >div.content > details:first-child {
  margin-top: 0;
}
details[open] >div.content > p:last-child,
details[open] >div.content > .tabs:last-child,
details[open] >div.content > ul:last-child,
details[open] >div.content > ol:last-child,
details[open] >div.content > .highlight:last-child,
details[open] >div.content > .note:last-child,
details[open] >div.content > details:last-child {
  margin-bottom: 0;
}
.md .frame-wrap {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.md .frame-wrap img,
.md .frame-wrap video {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.md .frame-wrap .frame {
  z-index: 1;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: absolute;
  background-size: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
.md .img-wrap .frame-wrap[part] {
  height: auto;
}
.md .frame-wrap#iphone11 img,
.md .frame-wrap#iphone11 video {
  width: 287px;
  margin-top: 19px;
  margin-bottom: 20px;
}
.md .frame-wrap#iphone11 .frame {
  background-image: url("https://cdn.jsdelivr.net/gh/volantis-x/cdn-volantis@3/img/frame/iphone11.svg");
  width: 329px;
  height: 658px;
}
.md .frame-wrap[part='top'] img,
.md .frame-wrap[part='top'] video {
  margin-bottom: 0 !important;
}
.md .frame-wrap:not([part='bottom']) .frame {
  top: 0;
}
.md .frame-wrap[part='bottom'] img,
.md .frame-wrap[part='bottom'] video {
  bottom: 0;
  margin-top: 0 !important;
}
.md .frame-wrap[part='bottom'] .frame {
  bottom: 0;
}
@media screen and (max-width: 500px) {
  .md .frame-wrap#iphone11 img,
  .md .frame-wrap#iphone11 video {
    width: 208px;
    margin-top: 13px;
    margin-bottom: 14px;
  }
  .md .frame-wrap#iphone11 .frame {
    width: 238px;
    height: 476px;
  }
}
.users-wrap {
  overflow: hidden;
}
.users-wrap .group-header {
  margin: 0 0 1rem;
}
.users-wrap .group-header p {
  margin: 0;
  font-size: 0.875rem;
}
.users-wrap .group-header p:first-child {
  font-size: 1.25rem;
  font-weight: 500;
}
.users-wrap .group-body {
  width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 2rem;
}
.users-wrap .friendsjs-wrap {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
.users-wrap .friendsjs-wrap .loading-wrap {
  min-height: 50px;
  margin: 2rem 0;
  text-align: center;
}
.users-wrap .user-card {
  flex-shrink: 1;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-items: stretch;
  width: 12.5%;
}
@media screen and (max-width: 980px) {
  .users-wrap .user-card {
    width: 14.28%;
  }
}
@media screen and (max-width: 900px) {
  .users-wrap .user-card {
    width: 16.66%;
  }
}
@media screen and (max-width: 820px) {
  .users-wrap .user-card {
    width: 20%;
  }
}
@media screen and (max-width: 768px) {
  .users-wrap .user-card {
    width: 16.66%;
  }
}
@media screen and (max-width: 500px) {
  .users-wrap .user-card {
    width: 25%;
  }
}
.users-wrap .user-card .card-link {
  margin: 0;
  width: 100%;
  color: var(--text-p1);
  font-size: 10px;
  font-weight: 500;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.2;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  overflow: hidden;
  position: relative;
  padding: 1rem 0.5rem;
}
.users-wrap .user-card .card-link img {
  -o-object-fit: cover;
     object-fit: cover;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  width: 48px;
  height: 48px;
  background: var(--card);
  border-radius: 64px;
  -webkit-border-radius: 64px;
  margin: 0 0 0.5rem;
}
.users-wrap .user-card .card-link >img {
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  -webkit-transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  -khtml-transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  -o-transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
  -ms-transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}
.users-wrap .user-card .card-link:hover {
  background: var(--block-hover);
}
.users-wrap .user-card .card-link:hover img {
  transform: scale(1.2) rotate(8deg);
  -webkit-transform: scale(1.2) rotate(8deg);
  -khtml-transform: scale(1.2) rotate(8deg);
  -moz-transform: scale(1.2) rotate(8deg);
  -o-transform: scale(1.2) rotate(8deg);
  -ms-transform: scale(1.2) rotate(8deg);
  box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
a.ghcard {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  line-height: 0;
}
.md .ghcard-group {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-gap: 0;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  margin: 0 -8px;
}
.md .ghcard-group .ghcard {
  margin: 8px;
}
.md .img {
  -o-object-fit: contain;
     object-fit: contain;
}
img.inline {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline !important;
  vertical-align: middle;
  transform: translateY(-4px);
  -webkit-transform: translateY(-4px);
  -khtml-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
}
.md .tag.link {
  margin-top: 1em;
  margin-bottom: 1em;
}
.md .link-card {
  margin-right: 1em;
  background: var(--color-block);
  display: inline-flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  width: 361px;
  max-width: 100%;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  color: var(--color-p);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
@media screen and (max-width: 425px) {
  .md .link-card {
    max-width: 100%;
    width: 100%;
  }
}
.md .link-card:hover {
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
.md .link-card div.left,
.md .link-card div.right {
  pointer-events: none;
}
.md .link-card div.left {
  width: 54px;
  height: 54px;
  margin: 12px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}
.md .link-card div.left i {
  font-size: 32px;
  line-height: 48px;
  margin-left: 4px;
}
.md .link-card div.left img {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: absolute;
  border-radius: 8px/4;
  -webkit-border-radius: 8px/4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.md .link-card div.right {
  overflow: hidden;
  margin-right: 16px;
}
.md .link-card p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.md .link-card p.text {
  font-weight: bold;
}
.md .link-card p.url {
  flex-shrink: 0;
  color: var(--color-meta);
  font-size: 0.8125rem;
}
.md .link-group {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}
@media screen and (max-width: 850px) {
  .md .link-group {
    grid-template-columns: 1fr;
  }
}
.md .link-group .tag.link {
  margin: 0;
}
.md .link-group .link-card {
  width: 100%;
}
audio,
video {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  max-width: 100%;
}
video {
  z-index: 1;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
video:hover {
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.24), 0 8px 16px 0px rgba(0,0,0,0.24);
  -webkit-box-shadow: 0 4px 8px 0px rgba(0,0,0,0.24), 0 8px 16px 0px rgba(0,0,0,0.24);
}
div.video {
  line-height: 0;
  text-align: center;
}
div.videos {
  max-width: calc(100% + 2 * 4px);
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -khtml-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  align-items: flex-end;
  margin: 1em -4px;
}
div.videos .video,
div.videos iframe {
  width: 100%;
  margin: 4px;
}
div.videos iframe {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  width: 100%;
  min-height: 300px;
}
div.videos.left {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
}
div.videos.center {
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
}
div.videos.right {
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -khtml-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -o-justify-content: flex-end;
  -ms-justify-content: flex-end;
}
div.videos.stretch {
  align-items: stretch;
}
div.videos[col='1'] .video,
div.videos[col='1'] iframe {
  width: 100%;
}
div.videos[col='2'] .video,
div.videos[col='2'] iframe {
  width: calc(50% - 2 * 4px);
}
div.videos[col='3'] .video,
div.videos[col='3'] iframe {
  width: calc(33.33% - 2 * 4px);
}
div.videos[col='4'] .video,
div.videos[col='4'] iframe {
  width: calc(25% - 2 * 4px);
}
div.note {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 16px;
  padding-left: calc(16px + 16px);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  font-size: 0.9375rem;
  background: var(--color-block);
  border-left: 4px solid #3dd9b6;
}
div.note h2,
div.note h3,
div.note h4,
div.note h5,
div.note h6 {
  margin-top: 3px;
  margin-bottom: 0;
  padding-top: 0 !important;
  border-bottom: initial;
}
div.note p,
div.note ul,
div.note ol,
div.note blockquote,
div.note img {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
div.note .link-card {
  background: var(--color-card);
}
div.note::before {
  position: absolute;
  top: calc(50% - 24px * 0.5);
  left: 4px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  vertical-align: middle;
  font-family: 'Font Awesome 6 Pro';
}
div.note::before {
  color: #3dd9b6;
  content: '\f054';
}
div.note::before {
  content: '\f054';
}
div.note.quote {
  background: #e8f4fd;
  border-color: #2196f3;
}
div.note.quote::before {
  color: #2196f3;
  content: '\f10d';
}
div.note.info {
  background: #ebfbf7;
  border-color: #3dd9b6;
}
div.note.info::before {
  color: #3dd9b6;
  content: '\f129';
}
div.note.success,
div.note.done {
  background: #ebf9ed;
  border-color: #3dc550;
}
div.note.success::before,
div.note.done::before {
  color: #3dc550;
  content: '\f00c';
}
div.note.warning {
  background: #fff8e9;
  border-color: #ffbd2b;
}
div.note.warning::before {
  color: #ffbd2b;
  content: '\f12a';
}
div.note.danger,
div.note.error {
  background: #feefee;
  border-color: #fe5f58;
}
div.note.danger::before,
div.note.error::before {
  color: #fe5f58;
  content: '\f00d';
}
div.note.radiation::before {
  content: '\f7b9';
}
div.note.bug::before {
  content: '\f188';
}
div.note.idea::before {
  content: '\f0eb';
}
div.note.link::before {
  content: '\f0c1';
}
div.note.paperclip::before {
  content: '\f0c6';
}
div.note.todo::before {
  content: '\f0ae';
}
div.note.message::before {
  content: '\f4ad';
}
div.note.guide::before {
  content: '\f277';
}
div.note.download::before {
  content: '\f019';
}
div.note.up::before {
  content: '\f102';
}
div.note.undo::before {
  content: '\f2ea';
}
div.note.play::before {
  content: '\f144';
}
div.note.clear {
  background: none;
  border-color: none;
}
div.note.light {
  background: #f5f5f5;
  border-color: #a1a1a1;
}
div.note.light::before {
  color: #a1a1a1;
}
div.note.gray {
  background: #f5f5f5;
  border-color: #696969;
}
div.note.gray::before {
  color: #696969;
}
div.note.theme {
  background: #ebfbf7;
  border-color: #3dd9b6;
}
div.note.theme::before {
  color: #3dd9b6;
}
div.note.red {
  background: #feefee;
  border-color: #fe5f58;
}
div.note.red::before {
  color: #fe5f58;
}
div.note.yellow {
  background: #fff8e9;
  border-color: #ffbd2b;
}
div.note.yellow::before {
  color: #ffbd2b;
}
div.note.green {
  background: #ebf9ed;
  border-color: #3dc550;
}
div.note.green::before {
  color: #3dc550;
}
div.note.cyan {
  background: #e8fafe;
  border-color: #1bcdfc;
}
div.note.cyan::before {
  color: #1bcdfc;
}
div.note.blue {
  background: #e8f4fd;
  border-color: #2196f3;
}
div.note.blue::before {
  color: #2196f3;
}
.sites-wrap .group-header {
  margin: 0 0 1rem;
}
.sites-wrap .group-header p {
  margin: 0;
  font-size: 0.875rem;
}
.sites-wrap .group-header p:first-child {
  font-size: 1.25rem;
  font-weight: 500;
}
.sites-wrap .group-body {
  width: 100%;
  margin-bottom: 2rem;
}
.sites-wrap .sitesjs-wrap {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
.sites-wrap .sitesjs-wrap .loading-wrap {
  min-height: 50px;
  margin: 2rem 0;
  text-align: center;
}
.sites-wrap .group-body {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: repeat(auto-fill, calc((100% - 3 * 1rem) / 4));
  margin-bottom: 2rem;
}
@media screen and (max-width: 1024px) {
  .sites-wrap .group-body {
    grid-template-columns: repeat(auto-fill, calc((100% - 2 * 1rem) / 3));
  }
}
@media screen and (max-width: 900px) {
  .sites-wrap .group-body {
    grid-template-columns: repeat(auto-fill, calc((100% - 1 * 1rem) / 2));
  }
}
@media screen and (max-width: 768px) {
  .sites-wrap .group-body {
    grid-template-columns: repeat(auto-fill, calc((100% - 2 * 1rem) / 3));
  }
}
@media screen and (max-width: 500px) {
  .sites-wrap .group-body {
    grid-template-columns: repeat(auto-fill, calc((100% - 1 * 1rem) / 2));
  }
}
.sites-wrap .group-body .site-card .card-link {
  width: 100%;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
}
.sites-wrap .group-body .site-card .card-link >img {
  width: 100%;
  height: 120px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.2);
}
@media screen and (max-width: 1024px) {
  .sites-wrap .group-body .site-card .card-link >img {
    height: 150px;
  }
}
@media screen and (max-width: 900px) {
  .sites-wrap .group-body .site-card .card-link >img {
    height: 180px;
  }
}
@media screen and (max-width: 768px) {
  .sites-wrap .group-body .site-card .card-link >img {
    height: 150px;
  }
}
.sites-wrap .group-body .site-card .card-link .info {
  margin-top: 0.5rem;
  line-height: 1.2;
}
.sites-wrap .group-body .site-card .card-link .info >img {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  -webkit-border-radius: 28px;
  float: left;
  margin-right: 8px;
  margin-top: 2px;
}
.sites-wrap .group-body .site-card .card-link .info span {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
.sites-wrap .group-body .site-card .card-link .info .title {
  font-weight: 500;
  color: var(--text-p1);
  font-size: 0.875rem;
  margin-top: 1px;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.sites-wrap .group-body .site-card .card-link .info .desc {
  font-size: 10px;
  margin-top: 2px;
  word-wrap: break-word;
  color: var(--text-p3);
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.sites-wrap .site-card .card-link >img {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.sites-wrap .site-card .card-link:hover >img {
  box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 4px 8px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1);
}
p.p.subtitle {
  font-weight: bold;
  color: #3eb399;
  font-size: 1.25rem !important;
  padding-top: 1.5rem;
}
p.p.subtitle:first-child {
  padding-top: 1rem;
}
span.p.logo,
p.p.logo {
  font-family: Dosis, "PingFang SC", "Microsoft YaHei", Helvetica, Arial, Helvetica, monospace;
}
span.p.code,
p.p.code {
  font-family: Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
}
span.p.left,
p.p.left {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: left;
}
span.p.center,
p.p.center {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: center;
}
span.p.right,
p.p.right {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  text-align: right;
}
span.p.small,
p.p.small {
  font-size: 0.875rem;
}
span.p.large,
p.p.large {
  font-size: 2.5rem;
  line-height: 1.4;
}
span.p.huge,
p.p.huge {
  font-size: 4rem;
  line-height: 1.4;
}
span.p.ultra,
p.p.ultra {
  font-size: 6rem;
  line-height: 1.4;
}
span.p.small,
p.p.small,
span.p.large,
p.p.large,
span.p.huge,
p.p.huge,
span.p.ultra,
p.p.ultra {
  margin: 0;
  padding: 0;
}
span.p.bold,
p.p.bold {
  font-weight: bold;
}
span.p.h1,
p.p.h1,
span.p.h2,
p.p.h2 {
  padding-bottom: 0.2rem;
  font-weight: 500;
}
span.p.h1,
p.p.h1 {
  font-size: 1.5rem;
  color: var(--color-h1);
  padding-top: 2em;
}
span.p.h2,
p.p.h2 {
  font-size: 1.5rem;
  color: var(--color-h2);
  padding-top: 2em;
  border-bottom: 1px solid rgba(68,68,68,0.1);
}
span.p.h3,
p.p.h3 {
  font-size: 1.25rem;
  color: var(--color-h3);
  padding-top: 2em;
}
span.p.h4,
p.p.h4 {
  font-size: 1.125rem;
  color: var(--color-h4);
  padding-top: 2em;
}
span.p.h5,
p.p.h5 {
  font-size: 1rem;
  color: var(--color-h5);
  padding-top: 1.5em;
}
span.p.red,
p.p.red {
  color: #fe5f58;
}
span.p.yellow,
p.p.yellow {
  color: #ffbd2b;
}
span.p.green,
p.p.green {
  color: #3dc550;
}
span.p.cyan,
p.p.cyan {
  color: #1bcdfc;
}
span.p.blue,
p.p.blue {
  color: #2196f3;
}
span.p.purple,
p.p.purple {
  color: #9c27b0;
}
span.p.gray,
p.p.gray {
  color: #999;
}
.swiper-container {
  width: 100%;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  --gap-p: 2rem;
}
.swiper-container:not(.swiper-container-initialized) {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
div.swiper-slide {
  text-align: center;
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  align-self: center;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  width: 50%;
}
div.swiper-slide img {
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.swiper-container[width='max'] div.swiper-slide {
  width: 100%;
}
.swiper-container[width='min'] div.swiper-slide {
  width: 25%;
}
.swiper-button-prev,
.swiper-button-next {
  padding: 1rem 0.5rem;
  margin-top: -2rem !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  background: rgba(255,255,255,0.25);
  transition: background 0.2s ease-out;
  -webkit-transition: background 0.2s ease-out;
  -khtml-transition: background 0.2s ease-out;
  -moz-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
  -ms-transition: background 0.2s ease-out;
  --swiper-theme-color: #000 !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: #fff !important;
  --swiper-theme-color: #ff5722 !important;
}
.md .table {
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}
.md .table table {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: table;
  width: 100%;
}
div.tabs {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  background: var(--color-card);
  border: 1px solid rgba(68,68,68,0.2);
  font-size: 0.9375rem;
}
div.tabs .highlight,
div.tabs p,
div.tabs ul,
div.tabs ol,
div.tabs div.note,
div.tabs details {
  margin-top: 1em;
  margin-bottom: 1em;
}
div.tabs ul.nav-tabs {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -khtml-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -o-justify-content: flex-start;
  -ms-justify-content: flex-start;
  margin: 0 !important;
  padding: 8px 8px 0 8px;
  background: var(--color-block);
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  line-height: 1.5;
}
div.tabs ul.nav-tabs li.tab {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}
div.tabs ul.nav-tabs li.tab:last-child {
  padding-right: 16px;
}
div.tabs ul.nav-tabs li.tab a {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  padding: 8px;
  text-align: center;
  font-size: 0.875rem;
  line-height: inherit;
  font-weight: bold;
  color: var(--color-meta);
  border: 1px solid transparent;
}
div.tabs ul.nav-tabs li.tab a:hover {
  color: var(--color-p);
}
div.tabs ul.nav-tabs li.tab a i {
  pointer-events: none;
}
div.tabs ul.nav-tabs li.tab.active a {
  cursor: default;
  color: var(--color-p);
  background: #fff;
  border: 1px solid rgba(68,68,68,0.2);
  border-bottom: 1px solid var(--color-card);
}
div.tabs .tab-content {
  border-top: 1px solid rgba(68,68,68,0.2);
  margin-top: -1px;
}
div.tabs .tab-content .tab-pane {
  padding: 16px;
}
div.tabs .tab-content .tab-pane:not(.active) {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
div.tabs .tab-content .tab-pane.active {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
div.tabs .tab-content .tab-pane > p:first-child,
div.tabs .tab-content .tab-pane > .tabs:first-child,
div.tabs .tab-content .tab-pane > ul:first-child,
div.tabs .tab-content .tab-pane > ol:first-child,
div.tabs .tab-content .tab-pane > .highlight:first-child,
div.tabs .tab-content .tab-pane > .note:first-child {
  margin-top: 0;
}
div.tabs .tab-content .tab-pane > p:last-child,
div.tabs .tab-content .tab-pane > .tabs:last-child,
div.tabs .tab-content .tab-pane > ul:last-child,
div.tabs .tab-content .tab-pane > ol:last-child,
div.tabs .tab-content .tab-pane > .highlight:last-child,
div.tabs .tab-content .tab-pane > .note:last-child {
  margin-bottom: 0;
}
.article del {
  color: #8e8e8e;
  -webkit-text-decoration-color: #8e8e8e;
          text-decoration-color: #8e8e8e;
}
.article u {
  color: var(--color-text);
  text-decoration: none;
  border-bottom: 1px solid #fe5f58;
}
.article emp {
  color: var(--color-text);
  border-bottom: 4px dotted #fe5f58;
}
.article wavy {
  color: var(--color-text);
  -webkit-text-decoration-style: wavy;
          text-decoration-style: wavy;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #fe5f58;
          text-decoration-color: #fe5f58;
}
.article psw {
  color: transparent;
  background: #a1a1a1;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.article psw:hover {
  color: var(--color-p);
  background: none;
}
.article kbd {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  border: 1px solid #d2d2d2;
  border-bottom-width: 2px;
  background: #fafafa;
  padding-left: 4px;
  padding-right: 4px;
}
div.timenode {
  position: relative;
}
div.timenode:before,
div.timenode:after {
  content: '';
  z-index: 1;
  position: absolute;
  background: rgba(61,217,182,0.5);
  width: 2px;
  left: 7px;
}
div.timenode:before {
  top: 0px;
  height: 6px;
}
div.timenode:after {
  top: 26px;
  height: calc(100% - 26px);
}
div.timenode:last-child:after {
  height: calc(100% - 26px - 16px);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
div.timenode .meta,
div.timenode .body {
  max-width: calc(100% - 24px);
}
div.timenode .meta {
  position: relative;
  color: var(--color-meta);
  font-size: 0.875rem;
  line-height: 32px;
  height: 32px;
}
div.timenode .meta:before,
div.timenode .meta:after {
  content: '';
  position: absolute;
  top: 8px;
  z-index: 2;
}
div.timenode .meta:before {
  background: rgba(61,217,182,0.5);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
}
div.timenode .meta:after {
  background: #3dd9b6;
  margin-left: 2px;
  margin-top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -khtml-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
div.timenode .meta p {
  font-weight: bold;
  margin: 0 0 0 24px;
}
div.timenode .body {
  margin: 4px 0 16px 24px;
  padding: 16px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  background: var(--color-block);
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
}
div.timenode .body:empty {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
div.timenode .body >*:first-child {
  margin-top: 0.25em;
}
div.timenode .body >*:last-child {
  margin-bottom: 0.25em;
}
div.timenode .body .highlight {
  border: 1px solid #e4e4e4;
}
div.timenode:hover .meta {
  color: var(--color-text);
}
div.timenode:hover .meta:before {
  background: rgba(255,87,34,0.5);
}
div.timenode:hover .meta:after {
  background: #ff5722;
  transform: scale(1);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
}
.article mjx-container {
  font-family: Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
  padding: 16px 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  min-width: 0 !important;
}
.article mjx-container[jax="CHTML"][display="true"],
.article .has-jax {
  overflow: auto hidden;
}
.article mjx-container + br {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
.fa.red,
.fas.red,
.far.red,
.fad.red,
.fal.red,
.fa-solid.red,
.fa-regular.red,
.fa-duotone.red,
.fa-light.red,
.fa-thin.red,
.iziToast>.iziToast-body .iziToast-icon.red {
  color: #f44336;
}
.fa.pink,
.fas.pink,
.far.pink,
.fad.pink,
.fal.pink,
.fa-solid.pink,
.fa-regular.pink,
.fa-duotone.pink,
.fa-light.pink,
.fa-thin.pink,
.iziToast>.iziToast-body .iziToast-icon.pink {
  color: #e91e63;
}
.fa.purple,
.fas.purple,
.far.purple,
.fad.purple,
.fal.purple,
.fa-solid.purple,
.fa-regular.purple,
.fa-duotone.purple,
.fa-light.purple,
.fa-thin.purple,
.iziToast>.iziToast-body .iziToast-icon.purple {
  color: #9c27b0;
}
.fa.indigo,
.fas.indigo,
.far.indigo,
.fad.indigo,
.fal.indigo,
.fa-solid.indigo,
.fa-regular.indigo,
.fa-duotone.indigo,
.fa-light.indigo,
.fa-thin.indigo,
.iziToast>.iziToast-body .iziToast-icon.indigo {
  color: #3f51b5;
}
.fa.light-blue,
.fas.light-blue,
.far.light-blue,
.fad.light-blue,
.fal.light-blue,
.fa-solid.light-blue,
.fa-regular.light-blue,
.fa-duotone.light-blue,
.fa-light.light-blue,
.fa-thin.light-blue,
.iziToast>.iziToast-body .iziToast-icon.light-blue {
  color: #4ba7ee;
}
.fa.deep-blue,
.fas.deep-blue,
.far.deep-blue,
.fad.deep-blue,
.fal.deep-blue,
.fa-solid.deep-blue,
.fa-regular.deep-blue,
.fa-duotone.deep-blue,
.fa-light.deep-blue,
.fa-thin.deep-blue,
.iziToast>.iziToast-body .iziToast-icon.deep-blue {
  color: #3367d6;
}
.fa.teal,
.fas.teal,
.far.teal,
.fad.teal,
.fal.teal,
.fa-solid.teal,
.fa-regular.teal,
.fa-duotone.teal,
.fa-light.teal,
.fa-thin.teal,
.iziToast>.iziToast-body .iziToast-icon.teal {
  color: #009688;
}
.fa.light-green,
.fas.light-green,
.far.light-green,
.fad.light-green,
.fal.light-green,
.fa-solid.light-green,
.fa-regular.light-green,
.fa-duotone.light-green,
.fa-light.light-green,
.fa-thin.light-green,
.iziToast>.iziToast-body .iziToast-icon.light-green {
  color: #8bc34a;
}
.fa.orange,
.fas.orange,
.far.orange,
.fad.orange,
.fal.orange,
.fa-solid.orange,
.fa-regular.orange,
.fa-duotone.orange,
.fa-light.orange,
.fa-thin.orange,
.iziToast>.iziToast-body .iziToast-icon.orange {
  color: #ff9800;
}
.fa.deep-orange,
.fas.deep-orange,
.far.deep-orange,
.fad.deep-orange,
.fal.deep-orange,
.fa-solid.deep-orange,
.fa-regular.deep-orange,
.fa-duotone.deep-orange,
.fa-light.deep-orange,
.fa-thin.deep-orange,
.iziToast>.iziToast-body .iziToast-icon.deep-orange {
  color: #ff5722;
}
.fa.brown,
.fas.brown,
.far.brown,
.fad.brown,
.fal.brown,
.fa-solid.brown,
.fa-regular.brown,
.fa-duotone.brown,
.fa-light.brown,
.fa-thin.brown,
.iziToast>.iziToast-body .iziToast-icon.brown {
  color: #795548;
}
.fa.blue-grey,
.fas.blue-grey,
.far.blue-grey,
.fad.blue-grey,
.fal.blue-grey,
.fa-solid.blue-grey,
.fa-regular.blue-grey,
.fa-duotone.blue-grey,
.fa-light.blue-grey,
.fa-thin.blue-grey,
.iziToast>.iziToast-body .iziToast-icon.blue-grey {
  color: #607d8b;
}
.fa.yellow,
.fas.yellow,
.far.yellow,
.fad.yellow,
.fal.yellow,
.fa-solid.yellow,
.fa-regular.yellow,
.fa-duotone.yellow,
.fa-light.yellow,
.fa-thin.yellow,
.iziToast>.iziToast-body .iziToast-icon.yellow {
  color: #fcec60;
}
.fa.TURQUOISE,
.fas.TURQUOISE,
.far.TURQUOISE,
.fad.TURQUOISE,
.fal.TURQUOISE,
.fa-solid.TURQUOISE,
.fa-regular.TURQUOISE,
.fa-duotone.TURQUOISE,
.fa-light.TURQUOISE,
.fa-thin.TURQUOISE,
.iziToast>.iziToast-body .iziToast-icon.TURQUOISE {
  color: #1abc9c;
}
.fa.EMERALD,
.fas.EMERALD,
.far.EMERALD,
.fad.EMERALD,
.fal.EMERALD,
.fa-solid.EMERALD,
.fa-regular.EMERALD,
.fa-duotone.EMERALD,
.fa-light.EMERALD,
.fa-thin.EMERALD,
.iziToast>.iziToast-body .iziToast-icon.EMERALD {
  color: #2ecc71;
}
.fa.PETERRIVE,
.fas.PETERRIVE,
.far.PETERRIVE,
.fad.PETERRIVE,
.fal.PETERRIVE,
.fa-solid.PETERRIVE,
.fa-regular.PETERRIVE,
.fa-duotone.PETERRIVE,
.fa-light.PETERRIVE,
.fa-thin.PETERRIVE,
.iziToast>.iziToast-body .iziToast-icon.PETERRIVE {
  color: #3498db;
}
.fa.AMETHYST,
.fas.AMETHYST,
.far.AMETHYST,
.fad.AMETHYST,
.fal.AMETHYST,
.fa-solid.AMETHYST,
.fa-regular.AMETHYST,
.fa-duotone.AMETHYST,
.fa-light.AMETHYST,
.fa-thin.AMETHYST,
.iziToast>.iziToast-body .iziToast-icon.AMETHYST {
  color: #9b59b6;
}
.fa.WETASPHALT,
.fas.WETASPHALT,
.far.WETASPHALT,
.fad.WETASPHALT,
.fal.WETASPHALT,
.fa-solid.WETASPHALT,
.fa-regular.WETASPHALT,
.fa-duotone.WETASPHALT,
.fa-light.WETASPHALT,
.fa-thin.WETASPHALT,
.iziToast>.iziToast-body .iziToast-icon.WETASPHALT {
  color: #34495e;
}
.fa.GREENSEA,
.fas.GREENSEA,
.far.GREENSEA,
.fad.GREENSEA,
.fal.GREENSEA,
.fa-solid.GREENSEA,
.fa-regular.GREENSEA,
.fa-duotone.GREENSEA,
.fa-light.GREENSEA,
.fa-thin.GREENSEA,
.iziToast>.iziToast-body .iziToast-icon.GREENSEA {
  color: #16a085;
}
.fa.NEPHRITIS,
.fas.NEPHRITIS,
.far.NEPHRITIS,
.fad.NEPHRITIS,
.fal.NEPHRITIS,
.fa-solid.NEPHRITIS,
.fa-regular.NEPHRITIS,
.fa-duotone.NEPHRITIS,
.fa-light.NEPHRITIS,
.fa-thin.NEPHRITIS,
.iziToast>.iziToast-body .iziToast-icon.NEPHRITIS {
  color: #27ae60;
}
.fa.BELIZEHOLE,
.fas.BELIZEHOLE,
.far.BELIZEHOLE,
.fad.BELIZEHOLE,
.fal.BELIZEHOLE,
.fa-solid.BELIZEHOLE,
.fa-regular.BELIZEHOLE,
.fa-duotone.BELIZEHOLE,
.fa-light.BELIZEHOLE,
.fa-thin.BELIZEHOLE,
.iziToast>.iziToast-body .iziToast-icon.BELIZEHOLE {
  color: #2980b9;
}
.fa.WISTERIA,
.fas.WISTERIA,
.far.WISTERIA,
.fad.WISTERIA,
.fal.WISTERIA,
.fa-solid.WISTERIA,
.fa-regular.WISTERIA,
.fa-duotone.WISTERIA,
.fa-light.WISTERIA,
.fa-thin.WISTERIA,
.iziToast>.iziToast-body .iziToast-icon.WISTERIA {
  color: #8e44ad;
}
.fa.MIDNIGHTBLUE,
.fas.MIDNIGHTBLUE,
.far.MIDNIGHTBLUE,
.fad.MIDNIGHTBLUE,
.fal.MIDNIGHTBLUE,
.fa-solid.MIDNIGHTBLUE,
.fa-regular.MIDNIGHTBLUE,
.fa-duotone.MIDNIGHTBLUE,
.fa-light.MIDNIGHTBLUE,
.fa-thin.MIDNIGHTBLUE,
.iziToast>.iziToast-body .iziToast-icon.MIDNIGHTBLUE {
  color: #2c3e50;
}
.fa.SUNFLOWER,
.fas.SUNFLOWER,
.far.SUNFLOWER,
.fad.SUNFLOWER,
.fal.SUNFLOWER,
.fa-solid.SUNFLOWER,
.fa-regular.SUNFLOWER,
.fa-duotone.SUNFLOWER,
.fa-light.SUNFLOWER,
.fa-thin.SUNFLOWER,
.iziToast>.iziToast-body .iziToast-icon.SUNFLOWER {
  color: #f1c40f;
}
.fa.CARROT,
.fas.CARROT,
.far.CARROT,
.fad.CARROT,
.fal.CARROT,
.fa-solid.CARROT,
.fa-regular.CARROT,
.fa-duotone.CARROT,
.fa-light.CARROT,
.fa-thin.CARROT,
.iziToast>.iziToast-body .iziToast-icon.CARROT {
  color: #e67e22;
}
.fa.ALIZARIN,
.fas.ALIZARIN,
.far.ALIZARIN,
.fad.ALIZARIN,
.fal.ALIZARIN,
.fa-solid.ALIZARIN,
.fa-regular.ALIZARIN,
.fa-duotone.ALIZARIN,
.fa-light.ALIZARIN,
.fa-thin.ALIZARIN,
.iziToast>.iziToast-body .iziToast-icon.ALIZARIN {
  color: #e74c3c;
}
.fa.CLOUDS,
.fas.CLOUDS,
.far.CLOUDS,
.fad.CLOUDS,
.fal.CLOUDS,
.fa-solid.CLOUDS,
.fa-regular.CLOUDS,
.fa-duotone.CLOUDS,
.fa-light.CLOUDS,
.fa-thin.CLOUDS,
.iziToast>.iziToast-body .iziToast-icon.CLOUDS {
  color: #ecf0f1;
}
.fa.CONCRETE,
.fas.CONCRETE,
.far.CONCRETE,
.fad.CONCRETE,
.fal.CONCRETE,
.fa-solid.CONCRETE,
.fa-regular.CONCRETE,
.fa-duotone.CONCRETE,
.fa-light.CONCRETE,
.fa-thin.CONCRETE,
.iziToast>.iziToast-body .iziToast-icon.CONCRETE {
  color: #95a5a6;
}
.fa.ORANGE,
.fas.ORANGE,
.far.ORANGE,
.fad.ORANGE,
.fal.ORANGE,
.fa-solid.ORANGE,
.fa-regular.ORANGE,
.fa-duotone.ORANGE,
.fa-light.ORANGE,
.fa-thin.ORANGE,
.iziToast>.iziToast-body .iziToast-icon.ORANGE {
  color: #f39c12;
}
.fa.PUMPKIN,
.fas.PUMPKIN,
.far.PUMPKIN,
.fad.PUMPKIN,
.fal.PUMPKIN,
.fa-solid.PUMPKIN,
.fa-regular.PUMPKIN,
.fa-duotone.PUMPKIN,
.fa-light.PUMPKIN,
.fa-thin.PUMPKIN,
.iziToast>.iziToast-body .iziToast-icon.PUMPKIN {
  color: #d35400;
}
.fa.POMEGRANATE,
.fas.POMEGRANATE,
.far.POMEGRANATE,
.fad.POMEGRANATE,
.fal.POMEGRANATE,
.fa-solid.POMEGRANATE,
.fa-regular.POMEGRANATE,
.fa-duotone.POMEGRANATE,
.fa-light.POMEGRANATE,
.fa-thin.POMEGRANATE,
.iziToast>.iziToast-body .iziToast-icon.POMEGRANATE {
  color: #c0392b;
}
.fa.SILVER,
.fas.SILVER,
.far.SILVER,
.fad.SILVER,
.fal.SILVER,
.fa-solid.SILVER,
.fa-regular.SILVER,
.fa-duotone.SILVER,
.fa-light.SILVER,
.fa-thin.SILVER,
.iziToast>.iziToast-body .iziToast-icon.SILVER {
  color: #bdc3c7;
}
.fa.ASBESTOS,
.fas.ASBESTOS,
.far.ASBESTOS,
.fad.ASBESTOS,
.fal.ASBESTOS,
.fa-solid.ASBESTOS,
.fa-regular.ASBESTOS,
.fa-duotone.ASBESTOS,
.fa-light.ASBESTOS,
.fa-thin.ASBESTOS,
.iziToast>.iziToast-body .iziToast-icon.ASBESTOS {
  color: #7f8c8d;
}
.highlight {
  position: relative;
}
.btn-copy {
  z-index: 1;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: inline-block;
  cursor: pointer;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  font-family: Menlo, Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 8px;
  color: var(--color-meta);
  background: var(--color-block);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1);
  position: absolute;
  top: 1px;
  right: 1px;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.btn-copy >i {
  margin-right: 4px;
}
.btn-copy:hover {
  color: #ff5722;
  background: #ffeee8;
}
.highlight:hover .btn-copy {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
}
.article pre:hover .btn-copy {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
}
.hljs:before,
.highlight:before {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-meta);
  font-size: 13px;
  padding: 4px 8px;
}
.hljs.md:before,
.highlight.md:before,
.hljs.markdown:before,
.highlight.markdown:before {
  content: "md";
}
.hljs.yaml:before,
.highlight.yaml:before {
  content: "YAML";
}
.hljs.json:before,
.highlight.json:before {
  content: "JSON";
}
.hljs.html:before,
.highlight.html:before {
  content: "HTML";
}
.hljs.js:before,
.highlight.js:before,
.hljs.javascript:before,
.highlight.javascript:before {
  content: "JS";
}
.hljs.css:before,
.highlight.css:before {
  content: "CSS";
}
.hljs.less:before,
.highlight.less:before {
  content: "Less";
}
.hljs.stylus:before,
.highlight.stylus:before {
  content: "Stylus";
}
.hljs.bash:before,
.highlight.bash:before {
  content: "bash";
}
.hljs.shell:before,
.highlight.shell:before {
  content: "shell";
}
.hljs.sh:before,
.highlight.sh:before {
  content: "sh";
}
.hljs.ini:before,
.highlight.ini:before {
  content: "ini";
}
.hljs.c:before,
.highlight.c:before {
  content: "C";
}
.hljs.cpp:before,
.highlight.cpp:before {
  content: "C++";
}
.hljs.objc:before,
.highlight.objc:before,
.hljs.objectivec:before,
.highlight.objectivec:before {
  content: "Objective-C";
}
.hljs.swift:before,
.highlight.swift:before {
  content: "Swift";
}
.hljs.java:before,
.highlight.java:before {
  content: "Java";
}
.hljs.python:before,
.highlight.python:before {
  content: "Python";
}
.hljs.php:before,
.highlight.php:before {
  content: "PHP";
}
.hljs.rust:before,
.highlight.rust:before {
  content: "Rust";
}
.hljs.sql:before,
.highlight.sql:before {
  content: "SQL";
}
.hljs.ruby:before,
.highlight.ruby:before {
  content: "Ruby";
}
.hljs.makefile:before,
.highlight.makefile:before {
  content: "Makefile";
}
.hljs.go:before,
.highlight.go:before {
  content: "Go";
}
.hljs.typescript:before,
.highlight.typescript:before {
  content: "TypeScript";
}
.highlight pre .line,
.highlight pre .params {
  color: rgba(68,68,68,0.9);
}
.highlight pre .line .addition {
  color: #3fa33f;
}
.highlight pre .line .deletion {
  color: #ee2b29;
}
.highlight pre .marked {
  background-color: rgba(254,213,66,0.4);
  padding: 2px 8px 2px 0;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 100%;
}
.highlight pre .title,
.highlight pre .attr,
.highlight pre .attribute {
  color: #3f51b5;
}
.highlight pre .comment {
  color: var(--color-meta);
}
.highlight pre .keyword,
.highlight pre .meta-keyword,
.highlight pre .javascript .function {
  color: #9c27b0;
}
.highlight pre .type,
.highlight pre .built_in,
.highlight pre .tag .name {
  color: #4ba7ee;
}
.highlight pre .variable,
.highlight pre .regexp,
.highlight pre .ruby .constant,
.highlight pre .xml .tag .title,
.highlight pre .xml .pi,
.highlight pre .xml .doctype,
.highlight pre .html .doctype,
.highlight pre .css .id,
.highlight pre .css .class,
.highlight pre .css .pseudo {
  color: #fd8607;
}
.highlight pre .number,
.highlight pre .preprocessor,
.highlight pre .literal,
.highlight pre .constant {
  color: #fd8607;
}
.highlight pre .class,
.highlight pre .ruby .class .title,
.highlight pre .css .rules .attribute {
  color: #ff9800;
}
.highlight pre .string,
.highlight pre .meta-string {
  color: #449e48;
}
.highlight pre .value,
.highlight pre .inheritance,
.highlight pre .header,
.highlight pre .ruby .symbol,
.highlight pre .xml .cdata {
  color: #4caf50;
}
.highlight pre .css .hexcolor {
  color: #6cc;
}
.highlight pre .function,
.highlight pre .python .decorator,
.highlight pre .python .title,
.highlight pre .ruby .function .title,
.highlight pre .ruby .title .keyword,
.highlight pre .perl .sub,
.highlight pre .javascript .title,
.highlight pre .coffeescript .title {
  color: #69c;
}
.highlight.html .line .tag .name,
.highlight.css .line .tag .name,
.highlight.less .line .tag .name,
.highlight.stylus .line .tag .name,
.highlight.html .line .selector-tag,
.highlight.css .line .selector-tag,
.highlight.less .line .selector-tag,
.highlight.stylus .line .selector-tag {
  color: #ee2b29;
}
.highlight.html .line .selector-class,
.highlight.css .line .selector-class,
.highlight.less .line .selector-class,
.highlight.stylus .line .selector-class,
.highlight.html .line .selector-attr,
.highlight.css .line .selector-attr,
.highlight.less .line .selector-attr,
.highlight.stylus .line .selector-attr {
  color: #fd8607;
}
.highlight.html .line .attribute,
.highlight.css .line .attribute,
.highlight.less .line .attribute,
.highlight.stylus .line .attribute {
  color: #3f51b5;
}
.highlight.html .line .number,
.highlight.css .line .number,
.highlight.less .line .number,
.highlight.stylus .line .number {
  color: #17afca;
}
.highlight.objc .line .meta,
.highlight.objectivec .line .meta,
.highlight.swift .line .meta,
.highlight.c .line .meta {
  color: #9c27b0;
}
.highlight.objc .line .meta-string,
.highlight.objectivec .line .meta-string,
.highlight.swift .line .meta-string,
.highlight.c .line .meta-string,
.highlight.objc .line .string,
.highlight.objectivec .line .string,
.highlight.swift .line .string,
.highlight.c .line .string {
  color: #fb3f1b;
}
.highlight.objc .line .class,
.highlight.objectivec .line .class,
.highlight.swift .line .class,
.highlight.c .line .class {
  color: rgba(68,68,68,0.9);
}
.highlight.objc .line .class .title,
.highlight.objectivec .line .class .title,
.highlight.swift .line .class .title,
.highlight.c .line .class .title {
  color: #1e80f0;
}
.highlight.objc .line .comment,
.highlight.objectivec .line .comment,
.highlight.swift .line .comment,
.highlight.c .line .comment {
  color: #3fa33f;
}
.highlight.json .line .attr {
  color: #e24f5a;
}
.highlight.json .line .literal {
  color: #3f51b5;
}
.highlight.yaml .line .attr {
  color: #e24f5a;
}
pre {
  position: relative;
}
.hljs {
  margin: -16px !important;
  padding: 16px !important;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.hljs::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.hljs::-webkit-scrollbar-track-piece {
  background: transparent;
}
.hljs::-webkit-scrollbar-thumb {
  background: #3dd9b6;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
.hljs::-webkit-scrollbar-thumb:hover {
  background: #ff5722;
}
.highlight {
  position: relative;
  width: 100%;
  margin: 1em 0;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  background: var(--color-block);
  font-size: 0.8125rem;
  font-family: Menlo, UbuntuMono, Monaco, monospace, courier, sans-serif;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  line-height: 1.5;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
.highlight:hover {
  background: var(--color-codeblock);
}
.highlight:hover figcaption {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  background: #ffeed2;
}
.highlight:hover .gutter {
  background: #ffedd0;
}
.highlight figcaption {
  font-size: 13px;
  position: sticky;
  left: 0;
  padding: 4px 8px 4px 8px;
  background: #eaeaea;
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px);
}
.highlight >table {
  overflow: auto;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  margin: 0;
  background-color: transparent;
  border: none;
}
.highlight >table::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.highlight >table::-webkit-scrollbar-track-piece {
  background: transparent;
}
.highlight >table::-webkit-scrollbar-thumb {
  background: transparent;
  cursor: pointer;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
.highlight >table:hover::-webkit-scrollbar-thumb {
  background: rgba(68,68,68,0.5);
}
.highlight >table:hover::-webkit-scrollbar-thumb:hover {
  background: #ff5722;
}
.highlight >table td,
.highlight >table th {
  padding: 0;
  border: none;
  line-height: 1.5;
}
.highlight >table tr {
  background-color: transparent;
}
.highlight >table tr:hover {
  background-color: transparent;
}
.highlight >table pre {
  overflow-y: hidden;
}
.highlight >table .gutter {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: 0 12px;
  text-align: right;
  border-width: 0;
  margin-left: 0;
  position: sticky;
  left: 0;
  z-index: 1;
  background: #e9e9e9;
}
.highlight >table .gutter pre {
  color: var(--color-meta);
}
.highlight >table pre {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
}
.highlight >table pre .code:before {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
}
.highlight >table .code {
  padding: 20px 16px;
  vertical-align: top;
  background-color: transparent;
}
.highlight >table .code:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  color: var(--color-meta);
  font-size: 13px;
  padding: 4px 8px;
}
.iziToast-texts {
  max-width: 300px !important;
  min-width: 200px !important;
}
@media screen and (max-width: 500px) {
  .iziToast-texts {
    max-width: unset !important;
    min-width: unset !important;
  }
}
.iziToast-title {
  margin-bottom: 6px !important;
  font-size: 1rem !important;
}
.iziToast-message {
  word-break: break-all !important;
}
@media screen and (max-width: 500px) {
  .l_header .list-v .aplayer,
  .l_header .list-v .aplayer-pic {
    border-radius: 4px;
    -webkit-border-radius: 4px;
    width: 64px;
    height: 64px;
  }
}
.aplayer-container {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -khtml-justify-content: center;
  -moz-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  min-height: 100px;
}
.aplayer-container meting-js {
  max-width: 100%;
}
.aplayer {
  max-width: 500px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  color: var(--color-text);
  font-family: Dosis, "PingFang SC", "Microsoft YaHei", Helvetica, Arial, Menlo, Monaco, monospace, sans-serif;
}
.aplayer .aplayer-list {
  text-align: left;
}
@media screen and (max-width: 500px) {
  .aplayer {
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }
}
.l_header .aplayer-volume-wrap {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none !important;
}
.aplayer.aplayer-fixed.aplayer-narrow .aplayer-body {
  left: -66px !important;
}
.aplayer.aplayer-fixed.aplayer-narrow .aplayer-body:hover {
  left: 0px !important;
}
p,
input,
pre>span,
textarea {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/text.png"), text;
}
.fancybox-container .fancybox-stage,
.fancybox__carousel .is-draggable {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/zoom-out.png"), zoom-out;
}
.fancybox a,
.fancybox-can-zoomIn .fancybox-content,
.fancybox__carousel .fancybox__slide.can-zoom_in .fancybox__content {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/zoom-in.png"), zoom-in;
}
span.btn>a:not([href]):hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover,
.gt-container .gt-btn.is--disable,
span.not-select {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/circle.png"), not-allowed;
}
html,
a:not([href]),
a:not([href]) p,
button[disabled],
html input[disabled],
.article .widget,
#l_body .post section.meta .new-meta-box .new-meta-item .notlink,
ul.list-v.rightmenu a,
span.btn a:not([href]),
div.tabs ul.nav-tabs li.tab.active a,
kbd,
.a > p,
.not-select,
.not-select p,
.aplayer .aplayer-info .aplayer-music,
.gt-container a.is--active,
.iziToast,
.iziToast p,
.iframe,
.embed-show,
p > img,
p > svg,
.fix-cursor-default {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/left_ptr.png"), default;
}
a,
psw,
button,
html input[type=button],
input[type=reset],
input[type=submit],
table::-webkit-scrollbar-thumb,
scrollbar::-webkit-scrollbar-thumb,
#scroll-down,
ul.list-v.rightmenu li.music a.nav.volume .aplayer-volume-bar,
#u-search .modal .modal-header .btn-close,
#u-search .modal .modal-footer .nav,
article .checkbox input,
details summary,
.article a.link-card,
div.tabs ul.nav-tabs li.tab a,
.btn-copy,
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content,
.waves-effect,
a.result.search-result-fix,
.tag > p,
.prev > p,
.next > p,
.author > p,
.list-h > a > p,
.category > a > p,
.friend-right p,
.v[data-class=v] a,
.v[data-class=v] .vbtn,
.v[data-class=v] .vicon,
.v[data-class=v] .vcards .vcard .vhead .vnick,
.v[data-class=v] .vcards .vcard .vh .vmeta .vat,
.v[data-class=v] .vwrap .vemojis i,
.v[data-class=v] .vcards .vcard .vcontent.expand,
.aplayer .aplayer-pic,
.aplayer .aplayer-info .aplayer-controller .aplayer-time .aplayer-icon,
.aplayer .aplayer-info .aplayer-controller .aplayer-bar-wrap,
.aplayer .aplayer-list ol li,
.aplayer .aplayer-list ol li .aplayer-list-author,
.aplayer .aplayer-list ol li .aplayer-list-index,
.waves-button,
.waves-button-input,
.waves-button:hover,
.waves-button:visited,
.fancybox-button,
.gt-container .gt-btn,
.gt-container .gt-user-inner,
.gt-container .gt-avatar-github,
.gt-container .gt-popup .gt-action,
.gt-container .gt-comment-like:hover,
.gt-container .gt-comment-edit:hover,
.gt-container .gt-comment-reply:hover,
.tk-icon,
.tk-avatar img,
.tk-meta a,
.tk-action-icon,
.fancybox,
.fix-cursor-pointer {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/pointer.png"), pointer;
}
.cur-default {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/left_ptr.png"), default !important;
}
.cur-pointer,
.carousel__button,
.fancybox__slide {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/pointer.png"), pointer !important;
}
.cur-not-allowed {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/circle.png"), not-allowed !important;
}
.cur-zoom-in,
.fancybox__carousel .fancybox__slide.can-zoom_in .fancybox__content,
.has-image[data-image-fit=contain].can-zoom_in .fancybox__image {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/zoom-in.png"), zoom-in !important;
}
.cur-zoom-out,
.fancybox__carousel .is-draggable,
.has-image[data-image-fit=contain].is-draggable .fancybox__image {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/zoom-out.png"), zoom-out !important;
}
.cur-text {
  cursor: url("https://unpkg.com/volantis-static@0.0.1660614606622/media/cursor/text.png"), text !important;
}
.common_read {
  z-index: auto !important;
  opacity: 1 !important;
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important;
  overflow: visible !important;
  transform: none !important;
  -webkit-transform: none !important;
  -khtml-transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
  -ms-transform: none !important;
  animation: none !important;
  -webkit-animation: none !important;
  -khtml-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  position: relative !important;
}
.body-wrapper.common_read {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
}
#safearea.common_read {
  padding-bottom: 16px;
}
@media screen and (max-width: 900px) {
  #safearea.common_read {
    padding: 0;
    margin: 0;
  }
}
#l_body.common_read {
  z-index: 2147483646 !important;
}
.read_cover {
  min-height: 10px !important;
}
@media screen and (max-width: 900px) {
  .read_cover {
    min-height: 0 !important;
  }
}
.common_read_bkg {
  background-color: var(--color-read-bkg) !important;
  opacity: 1 !important;
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 2147483645 !important;
  transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  -webkit-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  -khtml-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  -moz-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  -o-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
  -ms-transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
}
.common_read_hide {
  opacity: 0 !important;
  -webkit-opacity: 0 !important;
  -moz-opacity: 0 !important;
  z-index: -2147483645 !important;
}
.common_read_main {
  width: 840px !important;
  padding: 0 !important;
  margin: 0 auto;
  float: initial !important;
}
@media screen and (max-width: 900px) {
  .common_read_main {
    width: auto !important;
  }
}
.post_read {
  background-color: var(--color-read-post) !important;
  z-index: 2147483646 !important;
  overflow: visible !important;
  font-size: 1.15rem !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  box-shadow: 0 6px 12px 3px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 6px 12px 3px rgba(0,0,0,0.2);
}
div#rightmenu-wrapper {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: none;
  position: fixed;
  z-index: 2147483648;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
ul.list-v.rightmenu {
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  max-width: 240px;
  overflow: hidden;
}
ul.list-v.rightmenu.left {
  right: 0;
}
ul.list-v.rightmenu.top {
  bottom: 0;
}
ul.list-v.rightmenu a {
  cursor: default;
}
ul.list-v.rightmenu a.vlts-menu {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
  font-weight: normal;
}
ul.list-v.rightmenu >li>span {
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  display: -webkit-box /* OLD - iOS 6-, Safari 3.1-6 */;
  display: -moz-box /* OLD - Firefox 19- (buggy but mostly works) */;
  display: block;
  color: var(--color-list);
  font-size: 0.875rem;
  line-height: 36px;
  padding: 0 20px 0 16px;
  text-overflow: ellipsis;
  margin: 0 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}
@media screen and (max-width: 1024px) {
  ul.list-v.rightmenu >li>span {
    line-height: 40px;
  }
}
ul.list-v.rightmenu >li>span >i {
  margin-right: 8px;
}
ul.list-v.rightmenu >li>span:active,
ul.list-v.rightmenu >li>span.active {
  color: var(--color-list-hl);
}
ul.list-v.rightmenu >li>span:hover {
  color: var(--color-list-hl);
  background: var(--color-site-bg);
}
ul.list-v.rightmenu li.navigation,
ul.list-v.rightmenu li.music {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -khtml-justify-content: space-between;
  -moz-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
}
ul.list-v.rightmenu li.navigation a.icon-only,
ul.list-v.rightmenu li.music a.icon-only {
  line-height: 0;
  margin: 0;
  padding: 0;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  overflow: hidden;
  width: 32px;
  height: 32px;
}
ul.list-v.rightmenu li.navigation a.nav i,
ul.list-v.rightmenu li.music a.nav i {
  margin: 0;
  width: 32px;
  line-height: 32px;
}
ul.list-v.rightmenu li.navigation a.nav:first-child,
ul.list-v.rightmenu li.music a.nav:first-child {
  margin-left: 10px;
}
ul.list-v.rightmenu li.navigation a.nav:last-child,
ul.list-v.rightmenu li.music a.nav:last-child {
  margin-right: 10px;
}
ul.list-v.rightmenu li.music.name {
  display: flex /* NEW, Spec - Opera 12.1, Firefox 20+ */;
  display: flex;
  flex-direction: column;
  align-items: center;
}
ul.list-v.rightmenu li.music.name p.nav.music-title {
  color: #696969;
  font-size: 0.875rem;
  max-width: 128px;
  margin: 0 20px;
  overflow: hidden;
}
ul.list-v.rightmenu li.music.name p.nav.music-title:hover {
  background: none;
}
ul.list-v.rightmenu li.music .nav.volume {
  width: 100%;
  padding: 0 8px;
}
ul.list-v.rightmenu li.music .nav.volume:hover {
  background: transparent;
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar {
  margin: 4px 0 8px;
  position: relative;
  height: 8px;
  width: 100%;
  background: #ededed;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar i.left,
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar i.right {
  color: #696969;
  position: absolute;
  top: 0;
  width: 28px;
  line-height: 28px;
  transition: all 0.28s ease;
  -webkit-transition: all 0.28s ease;
  -khtml-transition: all 0.28s ease;
  -moz-transition: all 0.28s ease;
  -o-transition: all 0.28s ease;
  -ms-transition: all 0.28s ease;
  opacity: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  transform: scale(0.5) translateY(-18px);
  -webkit-transform: scale(0.5) translateY(-18px);
  -khtml-transform: scale(0.5) translateY(-18px);
  -moz-transform: scale(0.5) translateY(-18px);
  -o-transform: scale(0.5) translateY(-18px);
  -ms-transform: scale(0.5) translateY(-18px);
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar i.left {
  left: 0;
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar i.right {
  right: 0;
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar:hover {
  height: 28px;
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar:hover i {
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  transform: scale(1) translateY(0);
  -webkit-transform: scale(1) translateY(0);
  -khtml-transform: scale(1) translateY(0);
  -moz-transform: scale(1) translateY(0);
  -o-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
}
ul.list-v.rightmenu li.music .nav.volume .aplayer-volume-bar .aplayer-volume {
  background: #3dd9b6;
  height: 100%;
  width: 100%;
}
@media (prefers-color-scheme: dark) {
  :root {
    --color-mode: 'dark';
  }
  :root:not([color-scheme]) {
    --block-hover: #1c1c1c;
    --text-p1: #fff;
    --text-p3: #777;
    --card: #fff;
  }
  :root:not([color-scheme]) fa-solid,
  :root:not([color-scheme]) fa-regular,
  :root:not([color-scheme]) fa-duotone,
  :root:not([color-scheme]) fa-light,
  :root:not([color-scheme]) fa-thin,
  :root:not([color-scheme]) fal,
  :root:not([color-scheme]) fad,
  :root:not([color-scheme]) fa,
  :root:not([color-scheme]) svg.iconfont,
  :root:not([color-scheme]) img,
  :root:not([color-scheme]) .lazyload {
    filter: brightness(70%) !important;
  }
  :root:not([color-scheme]) .widget.blogger .content .social-wrapper a.social:hover {
    background: var(--color-card);
  }
  :root:not([color-scheme]) .widget >.content ul.entry a .badge,
  :root:not([color-scheme]) .widget >.content ul.popular-posts a .badge {
    color: unset !important;
  }
  :root:not([color-scheme]) .content {
    color: var(--color-p);
  }
  :root:not([color-scheme]) .widget >.content a {
    color: var(--color-p) !important;
  }
  :root:not([color-scheme]) .widget >.content a:hover {
    color: var(--color-list-hl) !important;
  }
  :root:not([color-scheme]) .note {
    background: var(--color-block) !important;
  }
  :root:not([color-scheme]) div.tabs ul.nav-tabs li.tab.active a {
    color: var(--color-p);
    background: var(--color-card);
  }
  :root:not([color-scheme]) kbd {
    background: var(--color-block) !important;
  }
  :root:not([color-scheme]) .cover-wrapper.dock .menu .list-h a:hover,
  :root:not([color-scheme]) .cover-wrapper.featured .menu .list-h a:hover,
  :root:not([color-scheme]) .cover-wrapper.focus .menu .list-h a:hover {
    color: var(--color-text) !important;
  }
  :root:not([color-scheme]) .gutter {
    background: var(--color-card) !important;
    background-color: var(--color-card) !important;
  }
  :root:not([color-scheme]) .highlight figcaption {
    background-color: #14161a;
  }
  :root:not([color-scheme]) .highlight pre .line,
  :root:not([color-scheme]) .highlight pre .params {
    color: rgba(158,142,142,0.9);
  }
  :root:not([color-scheme]) *:not(.highlight) >table tr {
    background-color: var(--color-card);
  }
  :root:not([color-scheme]) *:not(.highlight) >table th {
    background: var(--color-site-bg);
  }
  :root:not([color-scheme]) *:not(.highlight) table td,
  :root:not([color-scheme]) *:not(.highlight) table th {
    border-color: var(--color-site-bg);
  }
  :root:not([color-scheme]) *:not(.highlight) >table tr:hover {
    background: var(--color-codeblock);
  }
  :root:not([color-scheme]) .timenode .highlight {
    border-color: var(--color-site-bg) !important;
  }
  :root:not([color-scheme]) blockquote p {
    color: var(--color-p);
  }
  :root:not([color-scheme]) #archive-page .archive .all-tags ul li span {
    color: var(--color-text);
    background: #6f6f72;
  }
  :root:not([color-scheme]) .btn-copy:hover {
    background: var(--color-site-body);
  }
  :root:not([color-scheme]) .aplayer {
    background: var(--color-site-bg);
  }
  :root:not([color-scheme]) .aplayer .aplayer-list ol li:hover {
    background: rgba(61,217,182,0.2);
  }
  :root:not([color-scheme]) .aplayer .aplayer-list ol li.aplayer-list-light {
    background: var(--color-block);
  }
  :root:not([color-scheme]) .aplayer-info {
    background: var(--color-site-bg) !important;
  }
  :root:not([color-scheme]) .aplayer .aplayer-lrc:before {
    background: linear-gradient(180deg, #282c34 0, rgba(255,255,255,0));
  }
  :root:not([color-scheme]) .aplayer .aplayer-lrc:after {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(33,33,33,0.8));
  }
  :root:not([color-scheme]) .aplayer-pic {
    filter: brightness(70%);
  }
  :root:not([color-scheme]) .aplayer .aplayer-list ol li {
    border-top: 1px solid var(--color-card);
  }
  :root:not([color-scheme]) .aplayer.aplayer-withlist .aplayer-info {
    border-bottom: 1px solid var(--color-block);
  }
  :root:not([color-scheme]) .aplayer .aplayer-notice {
    background-color: var(--color-site-bg) !important;
  }
}
[color-scheme='dark'] {
  --block-hover: #1c1c1c;
  --text-p1: #fff;
  --text-p3: #777;
  --card: #fff;
}
[color-scheme='dark'] fa-solid,
[color-scheme='dark'] fa-regular,
[color-scheme='dark'] fa-duotone,
[color-scheme='dark'] fa-light,
[color-scheme='dark'] fa-thin,
[color-scheme='dark'] fal,
[color-scheme='dark'] fad,
[color-scheme='dark'] fa,
[color-scheme='dark'] svg.iconfont,
[color-scheme='dark'] img,
[color-scheme='dark'] .lazyload {
  filter: brightness(70%) !important;
}
[color-scheme='dark'] .widget.blogger .content .social-wrapper a.social:hover {
  background: var(--color-card);
}
[color-scheme='dark'] .widget >.content ul.entry a .badge,
[color-scheme='dark'] .widget >.content ul.popular-posts a .badge {
  color: unset !important;
}
[color-scheme='dark'] .content {
  color: var(--color-p);
}
[color-scheme='dark'] .widget >.content a {
  color: var(--color-p) !important;
}
[color-scheme='dark'] .widget >.content a:hover {
  color: var(--color-list-hl) !important;
}
[color-scheme='dark'] .note {
  background: var(--color-block) !important;
}
[color-scheme='dark'] div.tabs ul.nav-tabs li.tab.active a {
  color: var(--color-p);
  background: var(--color-card);
}
[color-scheme='dark'] kbd {
  background: var(--color-block) !important;
}
[color-scheme='dark'] .cover-wrapper.dock .menu .list-h a:hover,
[color-scheme='dark'] .cover-wrapper.featured .menu .list-h a:hover,
[color-scheme='dark'] .cover-wrapper.focus .menu .list-h a:hover {
  color: var(--color-text) !important;
}
[color-scheme='dark'] .gutter {
  background: var(--color-card) !important;
  background-color: var(--color-card) !important;
}
[color-scheme='dark'] .highlight figcaption {
  background-color: #14161a;
}
[color-scheme='dark'] .highlight pre .line,
[color-scheme='dark'] .highlight pre .params {
  color: rgba(158,142,142,0.9);
}
[color-scheme='dark'] *:not(.highlight) >table tr {
  background-color: var(--color-card);
}
[color-scheme='dark'] *:not(.highlight) >table th {
  background: var(--color-site-bg);
}
[color-scheme='dark'] *:not(.highlight) table td,
[color-scheme='dark'] *:not(.highlight) table th {
  border-color: var(--color-site-bg);
}
[color-scheme='dark'] *:not(.highlight) >table tr:hover {
  background: var(--color-codeblock);
}
[color-scheme='dark'] .timenode .highlight {
  border-color: var(--color-site-bg) !important;
}
[color-scheme='dark'] blockquote p {
  color: var(--color-p);
}
[color-scheme='dark'] #archive-page .archive .all-tags ul li span {
  color: var(--color-text);
  background: #6f6f72;
}
[color-scheme='dark'] .btn-copy:hover {
  background: var(--color-site-body);
}
[color-scheme='dark'] .aplayer {
  background: var(--color-site-bg);
}
[color-scheme='dark'] .aplayer .aplayer-list ol li:hover {
  background: rgba(61,217,182,0.2);
}
[color-scheme='dark'] .aplayer .aplayer-list ol li.aplayer-list-light {
  background: var(--color-block);
}
[color-scheme='dark'] .aplayer-info {
  background: var(--color-site-bg) !important;
}
[color-scheme='dark'] .aplayer .aplayer-lrc:before {
  background: linear-gradient(180deg, #282c34 0, rgba(255,255,255,0));
}
[color-scheme='dark'] .aplayer .aplayer-lrc:after {
  background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(33,33,33,0.8));
}
[color-scheme='dark'] .aplayer-pic {
  filter: brightness(70%);
}
[color-scheme='dark'] .aplayer .aplayer-list ol li {
  border-top: 1px solid var(--color-card);
}
[color-scheme='dark'] .aplayer.aplayer-withlist .aplayer-info {
  border-bottom: 1px solid var(--color-block);
}
[color-scheme='dark'] .aplayer .aplayer-notice {
  background-color: var(--color-site-bg) !important;
}
